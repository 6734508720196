<template>
<!--  <span>-->
<!--    <span class="mx-2">of Patient is </span>-->
<!--    <span style="display: inline-block">-->
<!--        <el-input-->
<!--          class="count-duration"-->
<!--          v-model="modalValue.date_count"-->
<!--          type="number"-->
<!--          clearable-->
<!--          placeholder="Count"-->
<!--        ></el-input>-->
<!--    </span>-->
<!--    <span class="mx-2"> many days in this </span>-->
<!--    <el-select-->
<!--      class="select-primary checklist-percentage-pipeline-stage mr-2"-->
<!--      v-model=value.current_stage_id-->
<!--      placeholder="Pipeline-Stage"-->
<!--      :class="[value.current_stage_id ? `option-selected` : '',]"-->
<!--      popper-class="checklist-percentage-pipeline-stage-dropdown"-->
<!--      @change="changeSelectWidth('checklist-percentage-pipeline-stage','checklist-percentage-pipeline-stage-dropdown')"-->
<!--    >-->
<!--      <el-option-->
<!--        class="select-primary"-->
<!--        v-for="(stage , index) in stagesToShow"-->
<!--        :key="index+'patient_stage_days'"-->
<!--        :label="stage.title"-->
<!--        :value="stage.id"-->
<!--      >-->
<!--      </el-option>-->
<!--    </el-select>-->
<!--    <span class=""> stage </span>-->
<!--  </span>-->
  <span>
    <span class="mx-2">of </span>
    <el-select
        class="select-primary checklist-percentage-pipeline-stage mr-2"
        v-model=value.current_stage_id
        placeholder="Pipeline-Stage"
        :class="[value.current_stage_id ? `option-selected` : '',]"
        popper-class="checklist-percentage-pipeline-stage-dropdown"
        @change="changeSelectWidth('checklist-percentage-pipeline-stage','checklist-percentage-pipeline-stage-dropdown')"
    >
      <el-option
          class="select-primary"
          v-for="(stage , index) in stagesToShow"
          :key="index+'patient_stage_days'"
          :label="stage.title"
          :value="stage.id"
      >
      </el-option>
    </el-select>
    <span class=""> is more than </span>

    <span style="display: inline-block">
        <el-input
          class="count-duration"
          v-model="modalValue.date_count"
          type="number"
          clearable
          placeholder="Count"
        ></el-input>
    </span>
    <span class="mx-2"> days</span>
  </span>
</template>

<script>
import pipelineAutomationRuleMixin from "../../../../mixins/pipelineAutomationRuleMixin";

export default {
  mixins: [pipelineAutomationRuleMixin],
  props: ['value','stages','alreadyUsedStage'],
  data() {
    return {};
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(input) {
        this.$emit('input', input)
      }
    },
    stagesToShow(){
      if(this.alreadyUsedStage){
        let stages = [...this.stages];
        stages.push(this.alreadyUsedStage);
        return stages;
      }else{
        return this.stages
      }
    }
  },
  watch: {},
  methods: {}
};
</script>

<style lang="scss">
.pipeline-automation-rule-dialog {
  .checklist-percentage-pipeline-stage {
    .el-input {
      .el-input__inner {
        width: 108px;
        padding-right: 0;
      }
    }
  }

  .count-duration {
    input {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 20px;
      width: 65px;
      height: 32px;
      margin-right: 5px;
      font: normal normal 600 15px/19px Open Sans
    }

    input:focus {
      border-color: #C0C4CC;
    }
  }
}
</style>

<style scoped>
</style>
