<template>
  <span>
    <span class="mx-2">is past</span>
        <span style="display: inline-block">

    <el-input
      class="count-duration"
      v-model="modalValue.date_count"
      type="number"
      clearable
      placeholder="Count"
    >

    </el-input>

    </span>
    <el-select
      class="select-primary  date-duration"
      v-model=modalValue.date_duration
      placeholder="Duration"
      :class="[modalValue.date_duration ? `option-selected` : '',]"
      popper-class="date-duration-dropdown"
      @change="changeSelectWidth('date-duration','date-duration-dropdown')"
    >
      <el-option
        class="select-primary"
        v-for="(dateDuration , index) in dateDurations"
        :key="index+'date_duration'"
        :label="dateDuration.label"
        :value="dateDuration.value"
      >
      </el-option>
    </el-select>
  </span>
</template>

<script>
import pipelineAutomationRuleMixin from "../../../../mixins/pipelineAutomationRuleMixin";

export default {
  mixins: [pipelineAutomationRuleMixin],
  props: ['value'],
  data() {
    return {
      countDurations: [
        {value: 1, label: '1'},
        {value: 2, label: '2'},
        {value: 3, label: '3'}
      ],
      dateDurations: [
        {value: 1, label: 'Day(s)'},
        {value: 2, label: 'Week(s)'},
        {value: 3, label: 'Month(s)'},
        {value: 4, label: 'Year(s)'}
      ]
    };
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(input) {
        this.$emit('input', input)
      }
    }
  },
  watch: {},
  methods: {}
};
</script>

<style lang="scss">
.pipeline-automation-rule-dialog {
  .date-duration {
    .el-input {
      .el-input__inner {
        width: 60px;
        padding-right: 0;
      }
    }
  }

  .count-duration {
    input {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 20px;
      width: 65px;
      height: 32px;
      margin-right: 5px;
      font: normal normal 600 15px/19px Open Sans
    }

    input:focus {
      border-color: #C0C4CC;
    }
  }
}
</style>

<style scoped>
</style>
