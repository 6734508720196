<template>
  <span>
    <span class="mx-2">of</span>
    <el-select
      class="select-primary check-list-temp-stage"
      v-model=value.temp_stage
      placeholder="Template Stage"
      :class="[value.temp_stage ? `option-selected` : '',]"
      popper-class="check-list-temp-stage-dropdown"
      @change="changeSelectWidth('check-list-temp-stage','check-list-temp-stage-dropdown')"
    >
      <el-option
        class="select-primary"
        v-for="(stage , index) in stages"
        :key="index+'temp_stage'"
        :label="stage.label"
        :value="stage.value"
      >
      </el-option>
    </el-select>
    <span class="m-0 d-inline-block" style="margin-right: 5px!important;margin-left: 5px!important;"> -- </span>
    <el-select
      class="select-primary  check-list"
      v-model=value.checklist_name
      placeholder="Checklist item"
      :class="[value.checklist_name ? `option-selected` : '',]"
      popper-class="check-list-dropdown"
      @change="changeSelectWidth('check-list','check-list-dropdown')"
    >
      <el-option
        class="select-primary"
        v-for="(checklist , index) in filteredChecklists"
        :key="index+'check-list'"
        :label="checklist.description"
        :value="checklist.description"
      >
      </el-option>
    </el-select>
    <span class="mx-2">is completed by</span>
    <el-select
        class="select-primary completed-by"
        v-model=value.completed_by
        placeholder="CompletedBy"
        :class="[value.completed_by ? `option-selected` : '',]"
        popper-class="completed-by-dropdown"
    >
      <el-option
          class="select-primary"
          v-for="(item , index) in dropdowns.completedBy"
          :key="index+'completed-by'"
          :label="item.label"
          :value="item.value"
      >
      </el-option>
    </el-select>
  </span>
</template>

<script>
import pipelineAutomationRuleMixin from "../../../../mixins/pipelineAutomationRuleMixin";
import {constants} from "../../../../util/constants";
export default {
  mixins: [pipelineAutomationRuleMixin],
  props: ['value', 'filteredChecklists', 'stages'],
  data() {
    return {
      dropdowns: {
        completedBy: [
          {value: constants.pipeline.completedBy.ANYONE, label: 'Anyone'},
          {value: constants.pipeline.completedBy.PATIENT, label: 'Patient'},
          {value: constants.pipeline.completedBy.PROGRAM, label: 'Program'}
        ]
      }
    };
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(input) {
        this.$emit('input', input)
      }
    }
  },
  watch: {

  },
  methods: {}
};
</script>

<style lang="scss">
.pipeline-automation-rule-dialog {
  .check-list-temp-stage {
    .el-input {
      .el-input__inner {
        width: 108px;
        padding-right: 0;
      }
    }
  }

  .check-list {
    .el-input {
      .el-input__inner {
        width: 95px;
        padding-right: 0;
      }
    }
  }
  .completed-by {
    .el-input {
      .el-input__inner {
        width: 94px;
      }
    }
  }
}
</style>
