<template>
  <div>
    <el-dialog
      title="Add Stage"
      :visible.sync="addStageDialogVisible"
      width="500px"
      :before-close="handleClose">
<!--      <validation-observer v-slot="{handleSubmit}" ref="formValidator">-->
        <el-form role="form" ref="addStageForm" :model="add_api_param" :rules="rules">
          <el-form-item prop="title">
            <label class="form-control-label">Stage Name</label>
            <el-input class=""
                        v-model="add_api_param.title"
                        placeholder="Stage Name">
            </el-input>
          </el-form-item>

          <el-checkbox fill="#94B527" class="mt-3 mb-3" v-model="add_api_param.rotting_status">
            <span>Rotting in (days)</span>
          </el-checkbox>
          <base-input class="mt-3" type="number"
                      name="rotting days"
                      v-model="add_api_param.rotting_days"
                      placeholder="Rotting days">
          </base-input>
          <div class="text-right mt-4">
            <el-button class="cancel-btn" @click="handleClose">Cancel</el-button>
            <el-button class="move_btn" :disabled="loader" @click="handleSubmit('addStageForm')">Add</el-button>
          </div>
        </el-form>
<!--      </validation-observer>-->
    </el-dialog>
  </div>
</template>

<script>
  import authHelper from "@/util/authHelper";

  export default {
    props: ['addStageDialogVisible', 'pipeline'],
    data() {
      return {
        loader: false,
        add_api_param: {
          title: '',
          rotting_status: false,
          rotting_days: 0,
          pipeline_id: '',
        },
        rules: {
          title: [
            {required: true, message: 'Please enter the stage name', trigger: 'blur'},
          ]
        },
      };
    },
    created() {

    },
    watch: {
      pipeline: {
        handler: function (obj) {
          this.add_api_param.pipeline_id = obj.id;
        },
      }
    },
    methods: {

      handleClose(done) {
        this.$emit('closeAddStageDialog')
        this.$refs['addStageForm'].resetFields()
      },

      handleSubmit(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.onSubmit()
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },

      async onSubmit() {
        // this will be called only after form is valid. You can do api call here to login
        this.loader = true
        await axios.post(this.$store.getters.getBaseUrl+'/api/create-stage',
          this.add_api_param,
        ).then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$store.dispatch('getPipelinesWithStagesAction').then(response => {
            this.$emit('updatePipelinesArray');
            this.$emit('closeAddStageDialog')
            this.add_api_param.title = ' ';

            this.$notify.success({
              title: 'Success',
              message: 'Stage created successfully.'
            });
          }).catch(error => {
          })
        }).catch(error => {
          var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          this.$notify.error({
            title: 'Error',
            message: message
          });
        }).finally(()=>this.loader = false)
      }
    },

  };
</script>

<style scoped>

  .move_btn {
    top: 505px;
    left: 850px;
    width: 103px;
    height: 45px;
    background: #832C44 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #32325D26;
    border-radius: 6px;
    border: 0px;
    color: white;
    opacity: 1;
  }

  .cancel-btn {
    top: 505px;
    left: 756px;
    width: 79px;
    height: 45px;
    background: #8898AA 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #32325D26;
    border: 0px;
    border-radius: 6px;
    color: white;
    opacity: 1;
  }

  .form-group {
    margin-bottom: 0px;
  }

</style>
