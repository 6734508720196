<template>
  <div>
    <div class="checklist_rule_item">
      <div class="d-flex flex-column" style="padding: 10px">
        <div class="d-flex align-items-start justify-content-between" style="padding-bottom: 20px">
          <div class="d-flex align-items-start">
            <div class="handle" style="margin-right: 20px">
              <img src="/img/icons/buttons/handle.svg" alt="+">
            </div>
            <p class="m-0 primary_text" style="">If
              <span v-if="rule.rule_type === constantsProp.SURGERY_DATE">
                <span class="secondary_text">
                  {{ ruleLabel[0] }}
                </span>
                {{ 'is past' }}
                <span class="secondary_text">{{ calculateTimeDuration(rule.days_count) }}</span>
              </span>
              <span v-if="rule.rule_type === constantsProp.SURGERY_DATE_ADDED">
                <span class="secondary_text">
                  {{ ruleLabel }}
                </span>
                {{ 'is added' }}
              </span>
              <span v-if="rule.rule_type === constantsProp.CHECKLIST">
                <span class="secondary_text">
                  {{ ruleLabel[0] }}
                </span>
                {{ ruleLabel[1] }}
                <span class="secondary_text">
                  {{ rule.new_todo_setting_description }} <span style="font: normal normal 500 14px/21px Open Sans">({{stages[rule.temp_stage]}})</span>
                </span>
                  {{ 'is completed by' }}
                <span class="secondary_text" style="text-transform: capitalize;">
                  {{ getCompletedBylabel(rule.completed_by) }}
                </span>
              </span>
              <span v-if="rule.rule_type === constantsProp.PIPELINE_STAGE_CHECKLIST_PERCENTAGE_COMPLETION">
                <span class="secondary_text">
                  {{ ruleLabel[0] }}
                </span>
                {{ 'is' }}
                <span class="secondary_text">{{ rule.percentage + '%'}}</span>
                {{ 'completed of patient in ' }}
                <span class="secondary_text_font" v-html="current_stage_text(rule.current_stage_type,rule.current_pipeline_stage)"></span>
              </span>
              <span v-if="rule.rule_type === constantsProp.PATIENT_STAGE_DAYS">
                <span class="secondary_text">
                {{ ruleLabel }}
                  </span>
                {{' of '}}
                <span class="secondary_text"> {{ rule.current_pipeline_stage.title}} </span>
                {{' is more than '}}
                <span class="secondary_text">{{ rule.days_count }}</span>
                {{' days '}}
              </span>

              {{ 'then move the patient to the' }}
              <span class="secondary_text">{{ rule.pipeline_stage.pipeline.editable_title }} -- {{ rule.pipeline_stage.title }}</span>
            </p>
          </div>
          <div class="priority-tag">
            <span>Priority {{ priority }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between buttons_div">
          <span>{{ changeDateTimezoneAndFormat(rule.created_at) }}</span>
          <div class="d-flex align-items-center">
            <div class="d-flex toggle align-items-center">
              <span class="pr-3" :class="getStatus(!rule.active)">Off</span>
              <base-switch class="status-toggle"
                           ref="templateSwitcher" onText="" offText=""
                           v-model="rule.active"
                           @input="toggleActive('active', rule.active)"
              >
              </base-switch>
              <span class="pl-3" :class="getStatus(rule.active)">On</span>
            </div>
            <img alt="trash" src="/img/icons/buttons/edit.svg" @click="$emit('editPipelineAutomationRule', rule=rule)">
            <img alt="trash" src="/img/icons/buttons/trash-rectangle.svg" @click="$emit('deletePipelineAutomationRule', id=rule.id)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {constants} from "../../../util/constants";
import moment from "moment";
  export default {
    props:{
      ruleItem: {
        type: Object,
        default: null,
        description: 'Pipeline Automation Rule'
      },
      pipelineTitle: {
        type: String,
        default: null,
        description: 'Pipeline name'
      },
      index: {
        type: Number,
        default: null,
        description: 'Pipeline Automation Rule Index'
      }
    },
    data() {
      return {
        rule: this.ruleItem,
        stages: ['', 'Pre-Op', 'Post-Op', 'Weight-Management'],
      }
    },
    methods: {
      toggleActive() {
        this.$emit('toggleActive', {index: this.index, id: this.rule.id, active: this.rule.active});
      },
      calculateTimeDuration(days_count) {
        if (days_count % 365 == 0) {
          return `${days_count / 365} Year(s)`;
        } else if (days_count % 30 == 0) {
          return `${days_count / 30} Month(s)`;
        } else if (days_count % 7 == 0) {
          return `${days_count / 7} Week(s)`;
        } else {
          return `${days_count} Days(s)`;
        }
      },
      changeDateTimezoneAndFormat(date) {
        return moment.utc(date).local().format('MMM DD, YYYY - hh:mm A')
      },
      getStatus(status) {
        return status ? 'active' : 'inactive';
      },
      getCompletedBylabel(value){
        let constanss = constants.pipeline.completedBy;
        let label = '';
        for(const p in constanss) {
          if(constanss[p]==value){
            label = p;
            break;
          }
        }
        return label;
      },
      current_stage_text(value,currentStage){
        let text = "";
        if(value == constants.pipeline.currentPipelineStageType.ANY_STAGE){
          text =  `any stage`
        }else if(value == constants.pipeline.currentPipelineStageType.THIS_STAGE){
          text =  `this (<span style="color: #32325D;">${currentStage.title})</span> stage`
        }else if(value == constants.pipeline.currentPipelineStageType.AFTER_THIS_STAGE){
          text =  `after this (<span style="color: #32325D;">${currentStage.title})</span> stage`
        }
        return text;
      },
    },
    computed: {
      constantsProp() {
        return constants.pipeline.automationRule
      },
      priority(){
        // index is used instead of order_key because no code is required to change that order_key as we have to display order_key number.
        // Array index is changed automatically when item drag drop
        return this.index + 1;
      },
      ruleLabel(){
        let ruleType = this.$store.getters['pipelineModule/getAutomationRuleType'].find(item=> item.value == this.rule.rule_type);
        if(ruleType){
          if([constants.pipeline.automationRule.PATIENT_STAGE_DAYS, constants.pipeline.automationRule.SURGERY_DATE_ADDED].includes(ruleType.value)){
            return ruleType.label;
          }
          return ruleType.label.split(' ');
        }
        return "";
      }
    },
  };
</script>

<style lang="scss" scoped>
  .checklist_rule_item {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0 2px 5px #8898AA26;
    border-radius: 6px;

    .active{
     color: #172B4D;
    }
    .inactive{
     color: #8898AA;
    }

    .handle img {
      cursor: move;
    }

    .primary_text {
      font: normal normal 400 20px/27px Open Sans;
      font-weight: 400;
      margin-top: -2px !important;
    }

    .secondary_text {
      font: normal normal 600 20px/27px Open Sans;
      margin-top: -5px !important;
      color: #32325D;
    }
    .secondary_text_font {
      font: normal normal 600 20px/27px Open Sans;
    }

    .priority-tag {
      min-width: 102px;

      span {
        border-radius: 4px;
        padding: 1px 17px;
        background: #f1d3d3;
        color: #832C44
      }
    }

    .buttons_div {
      padding-left: 36px;
      font: normal normal normal 14px/19px Open Sans;
      color: #8898aa;

      .toggle {
        margin-right: 15px;
      }

      img {
        margin-left: 5px;
        cursor: pointer;
      }
    }

  }

</style>
