<template>
  <div v-if="user.patient" @click.self="navigateToPatientDetail(user.user_id)" :class="stages_border_classes[user.patient.current_stage]"
    class=" cardStyle py-2 px-2 mb-3 flex justify-between text-left pipeline-patients-card cursor-move green_pipeline_border">
    <el-tooltip
      class="item" effect="dark"
      :content="hoverPatientInfo()"
      placement="bottom-start"
      :visible-arrow="false"
    >
      <router-link :to="{name: 'PatientDetail', params: {id: user.user_id}}">
        <p class="name-font ellipsis-overflow-text my-3">{{ (user.patient) ? user.patient.first_name : '' }}
          {{ (user.patient) ? user.patient.last_name : '' }}</p>
      </router-link>
    </el-tooltip>

    <div style="min-height: 20px" class=" d-flex justify-content-space-between ">
      <template  v-if="user.checklists && user.checklists.length>0">
        <el-popover v-for="checklist in user.checklists"
                    v-if="stages_in_checklist[user.patient.current_stage] == checklist.temp_stage"
                    placement="top-start"
                    trigger="hover"
                    :visible-arrow="true"
                    width="fit-content"
                    popper-class="checklist-popover-max-width"
                    class="checklist-indication-bar"
                    :class="checklistColor(checklist)"
        >
            <a :href="`/checklists/${checklist.user_id}`" slot="reference">
              <div class="w-100 h-100"></div>
            </a>
            <p class="m-0 w-100 ellipsis-overflow-text"><span class="tooltip-font-bold">Checklist Item: </span>
              <span class="tooltip-font">{{ checklist.todo_name }}</span></p>
            <p class="m-0"><span class="tooltip-font-bold">Due Date: </span>
              <span class="tooltip-font">{{ checklist.todo_due_date }}</span></p>
            <p class="m-0">
              <span class="tooltip-font-bold">Completed Date: </span>
              <span class="tooltip-font">{{ checklist.is_completed_date }}</span>
            </p>
            <p class="m-0">
              <span class="tooltip-font-bold">Completed By: </span>
              <span class="tooltip-font">{{ checklist.checked_by }}</span>
            </p>
        </el-popover>
      </template>
    </div>

    <router-link :to="{name: 'PatientDetail', params: {id: user.user_id}}">
      <div class="row mx-0 justify-content-between align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <img
            :src="(user.patient && (user.patient.opened_app > 0))  ? '/img/svg/phone-green-wob.svg' : '/img/svg/phone-wob.svg'"
            alt="">
          <img v-if="user.latest_pipeline_patient_alert && user.latest_pipeline_patient_alert != ''" class="ml-2"
               src="/img/svg/alert.svg" alt="">
          <el-tooltip
            v-if="user.last_pipeline_automation_rule_check && user.latest_pipeline_automation_rule_applied"
            class="item" effect="dark"
            :content="`Patient auto-moved here from ${user.latest_pipeline_automation_rule_applied.from_stage_name} stage of ${user.latest_pipeline_automation_rule_applied.from_pipeline_name} pipeline.`"
            placement="bottom-start"
            :visible-arrow="false"
          >
          <img src="/img/svg/shuffle.svg" class="ml-2" alt="">
          </el-tooltip>

        </div>
        <div class="days-container" v-if="(user.percentage && user.percentage!==null )|| user.percentage === 0">
          <span>{{ user.percentage }} %</span>
        </div>
        <div class="days-container" :class="{rotting:stage.rotting_status == 1 && getDaysDiff >stage.rotting_days}">
          <span>{{ `${getDaysDiff} day${getDaysDiff != 1 ? 's': ''}` }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import moment from 'moment';
import Helper from "@/util/globalHelpers";

export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    stage: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      stages_in_checklist: {
        25: 1,
        50: 1,
        75: 2,
        100: 2,
        125: 2,
        150: 3,
      },
      stages_border_classes: {
        25:'border-considering-it',
        50: 'border-pre-op',
        75: 'border-post-op',
        100: 'border-post-op',
        125: 'border-post-op',
        150: 'border-non-surgical',
      }
    }
  },
  computed: {
    getDaysDiff() {
      let today = moment.utc();
      let date = moment.utc(this.user.stage_times_from_date);
      let minutes = today.diff(date, 'minutes');

      return Math.round((minutes / 60) / 24);// converting minutes into days
    },
  },
  methods: {

    // This function gets called on hovering over patient name in Card and display toolTip with some user info
    hoverPatientInfo() {
      let toolTip = '';
      toolTip = this.user.patient.first_name + ' ' + this.user.patient.last_name
      toolTip += (this.user.patient.email) ? ', ' + this.user.patient.email : ''
      toolTip += (this.user.patient.phone) ? ', ' + this.user.patient.phone : ''
      toolTip += (this.user.patient.dob) ? ', ' + this.reformattingDate(this.user.patient.dob) : ''
      toolTip += (this.user.patient.mrn_number) ? ', ' + this.user.patient.mrn_number : ''
      return toolTip
    },

    // This function gets date and reformat it in (Month Day, Year)
    reformattingDate(userDate) {
      if (!isNaN(Date.parse(userDate))) {
        const date = new Date(userDate)
        return Helper.extractAndFormatDate(date.toDateString(), false)
      }
    },

    navigate() {
    },

    navigateToPatientDetail(patientId) {
      this.$router.push({
        name: 'PatientDetail',
        params: {
          id: patientId
        }
      })
    },
    navigateToUserChecklist(patientId) {
      this.$router.push({
        name: 'Checklist',
        params: {
          id: patientId
        }
      })
    },

    checklistColor(checklist) {
      let checklistColor = 'checklist-gray';
      let currentDate = moment().format('YYYY-MM-DD');

      if (checklist.checked_by == 'Program') {
        checklistColor = 'checklist-green';
      } else if (checklist.checked_by == 'Patient') {
        checklistColor = 'checklist-yellow';
      } else if ((!checklist.is_completed_date || checklist.is_completed_date == '') && moment().format('YYYY-MM-DD') > moment(checklist.todo_due_date).format('YYYY-MM-DD')) {
        checklistColor = 'checklist-red';
      }else if (checklist.checked_by == 'Waiting for paperwork'){
        checklistColor = 'checklist-blue';
      }

      return checklistColor;
    }
  }
}
</script>

<style scoped>

.tooltip-font-bold {
  font: normal normal bold 12px/14px Karla;
}

.tooltip-font {
  font: normal normal normal 12px/14px Karla;
}

.pipeline-patients-card {
  position: relative;
}

.pipeline-patients-card a {
  text-decoration: none !important;
  color: black !important;
}

.pipeline-patients-card:hover {
  opacity: 1;
  cursor: pointer;
  transition: all 0.4s ease !important;
}

.name-font {
  font: normal normal bold 14px/17px Karla;
  color: black;
  letter-spacing: 0;
  margin-bottom: 35px !important;
}

.days-container {
  background: #C4C4C44D 0 0 no-repeat padding-box;
  border-radius: 5px;
  font: normal normal normal 11px/13px Karla;
  color: #32325D;
  padding: 3px 7px 2px 8px;
  letter-spacing: 0;
  font-weight: 500;

}

.days-container.rotting {
  background: #F56036 0 0 no-repeat padding-box;
  color: #FFFFFF;
  font-weight: 600;
}


.checklist-indication-bar {
  height: 4px;
  flex-grow: 1;
  margin: 0 0.05rem 0 0.05rem;
}

.checklist-indication-bar:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.checklist-indication-bar:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

}

.checklist-green {
  background-color: #2DCE89;
}

.checklist-red {
  background-color: #FB537A;
}

.checklist-blue {
  background-color: #557a94;
}

.checklist-yellow {
  background-color: #FFCD16;
}

.checklist-gray {
  background-color: #EDEDED;
}

.border-pre-op {
  box-shadow: 0px 1px 6px #10101005;
  border: 2px solid #B2D4FE;
}

.border-post-op {
  box-shadow: 0px 1px 6px #10101005;
  border: 2px solid #CEE5D0;
}

.border-non-surgical {
  box-shadow: 0px 1px 6px #10101005;
  border: 2px solid #FEB7B7;
}

.border-considering-it {
  box-shadow: 0px 1px 6px #10101005;
  border: 2px solid #FFDEAC;
}
</style>

<style>
.checklist-popover-max-width {
  max-width: 400px;
}
</style>
