<template>
  <div>
    <el-dialog
      title="Edit Pipeline"
      :visible.sync="editPipelineDialogVisible"
      width="500px"
      :before-close="handleClose"
      v-loading="loader"
      >
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" @submit.prevent="handleSubmit(onSubmit)">
          <base-input class=""
                      :rules="{required: true}"
                      label="Pipeline Name" v-model="add_api_param.title"
                      placeholder="Pipeline Name">
          </base-input>
          <div class="text-right mt-4">
            <el-button class="cancel-btn" @click="handleClose">Cancel</el-button>
            <el-button class="move_btn" native-type="submit" type="primary" @click="handleConfirm" :disabled="loader">Update</el-button>
          </div>
        </form>
      </validation-observer>
    </el-dialog>
  </div>
</template>

<script>
  import authHelper from "@/util/authHelper";

  export default {
    props: ['editPipelineDialogVisible', 'pipeline'],
    data() {
      return {
        loader: false,
        add_api_param: {
          title: '',
          id: ''
        },
      };
    },
    created() {
      console.log(this.pipeline)
    },
    watch: {
      pipeline: {
        handler: function (obj) {
          this.add_api_param.title = obj.editable_title;
          this.add_api_param.id = obj.id;
        },
      }
    },
    methods: {

      handleClose(done) {
        this.add_api_param.title = this.pipeline.editable_title
        this.$emit('closeEditPipelineDialog')
      },
      async onSubmit() {
        // this will be called only after form is valid. You can do api call here to login
        let vm = this;
        vm.loader = true;
        await axios.put(this.$store.getters.getBaseUrl+'/api/update-pipeline',
          this.add_api_param,
        ).then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$store.dispatch('getPipelinesWithStagesAction').then(response => {
            this.$emit('closeEditPipelineDialog')
            this.$emit('updatePipelinesArray')
            this.$notify.success({
              title: 'Success',
              message: 'Pipeline updated successfully.'
            });
          }).catch(error => {
          })
        }).catch(error => {
          var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          this.$notify.error({
            title: 'Error',
            message: message
          });
        }).finally(() => {
          vm.loader = false;
        });
      }
    },

  };
</script>

<style scoped>

  .move_btn {
    top: 505px;
    left: 850px;
    width: 103px;
    height: 45px;
    background: #832C44 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #32325D26;
    border-radius: 6px;
    border: 0px;
    color: white;
    opacity: 1;
  }

  .cancel-btn {
    top: 505px;
    left: 756px;
    width: 79px;
    height: 45px;
    background: #8898AA 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #32325D26;
    border: 0px;
    border-radius: 6px;
    color: white;
    opacity: 1;
  }

</style>
