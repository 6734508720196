<template>
  <div>
    <el-dialog
      :title="titleText"
      :visible.sync="addPipelineAutomationRuleDialogVisible"
      width="650px"
      custom-class="pipeline-automation-rule-dialog"
      :before-close="handleClose"
    >
        <form role="form" @submit.prevent="onSubmit" v-loading="loaders.modal">
          <div class="d-flex flex-column"
          :class="{ 'pipeline-automation-border': isSurgeryRulesSelected, 'active': ruleActive(pipelineAutomationRuleConstants.SURGERY_DATE) }"
          @click="clickRule(pipelineAutomationRuleConstants.SURGERY_DATE)">
            <div class="text-break d-flex align-items-center">
              <div class="">
                <el-radio :label="pipelineAutomationRuleConstants.SURGERY_DATE" v-model="api_param.rule_type" class="m-0 pipeline-automation-radio" v-if="isSurgeryRulesSelected">&#8203;</el-radio>
                <span class="mr-2" v-if="api_param.rule_type">If</span>
                <el-select
                  class="select-primary automation-rule-select"
                  :class="[api_param.rule_type ? `option-selected` : '',]"
                  popper-class="automation-rule-select-dropdown"
                  v-model=api_param.rule_type
                  placeholder="If this happens"
                  @change="changeSelectWidth('automation-rule-select','automation-rule-select-dropdown')"
                  v-if="![pipelineAutomationRuleConstants.SURGERY_DATE_ADDED].includes(api_param.rule_type)"
                >
                  <el-option
                    class="select-primary"
                    v-for="(rule_type , index) in rule_types_filtered"
                    :key="index+'rule_type'"
                    :label="api_param.rule_type && api_param.rule_type === rule_type.value ? rule_type.label2 : rule_type.label"
                    :value="rule_type.value"
                  >
                  </el-option>
                </el-select>
                <strong v-else class="pipeline-automation-rule-label"> {{ selectedRuleTypeLabel }} </strong>

                <!--              SurgeryDate Rule-->
                <surgery-date-rule v-if="isSurgeryRulesSelected" v-model="surgeryRuleApiParams"/>

                <!--              Checklist Completion Rule-->
                <checklist-completion-rule v-else-if="api_param.rule_type===2"
                                           v-model="checklistCompletionRuleApiParams"
                                           :filtered-checklists="filteredChecklists" :stages="dropdowns.stages"/>

                <!--              Pipeline Stage Checklists Percentage Completion Rule-->
                <pipeline-stage-checklists-percentage-completion-rule v-else-if="api_param.rule_type===4"
                                                                      v-model="pipelineStageCompletionRuleApiParams"
                                                                      :stages="currentPipelineStages"/>

                <!--                Patient Stage Days Rule-->
                <patient-stage-days-rule v-else-if="api_param.rule_type===5"
                                         v-model="patientStageDaysRuleApiParams"
                                         :stages="currentPipelineStagesForPatientStageDaysRule"
                                         :already-used-stage="editIncludedStage"/>

              </div>
            </div>

            <img class="my-2" :style="`opacity: ${arrowOpacity}`" src="/img/svg/arrow_down_green.svg" height="21" width="16" alt="image">

            <div>
              <div v-if="showOnOldRules">
                <span class="ml-0 my-0 mr-2 d-inline-block">Then move the patient to </span>
                <el-select
                  class="select-primary automation-rule-pipeline-select"
                  v-model=api_param.next_pipeline_id
                  placeholder="Pipeline"
                  :class="[api_param.next_pipeline_id !==null && api_param.next_pipeline_id !==undefined ? `option-selected` : '',]"
                  popper-class="automation-rule-pipeline-select-dropdown"
                  @change="pipelineIndexChange"
                >
                  <el-option
                    class="select-primary"
                    v-for="(pipeline , index) in response.pipelines"
                    :key="index+'next_pipeline'"
                    :label="pipeline.editable_title"
                    :value="index"
                  >
                  </el-option>
                </el-select>
                <span class="mx-2 my-0 d-inline-block"> -- </span>
                <el-select
                  class="select-primary automation-rule-stage-select"
                  v-model=api_param.pipeline_stage_id
                  placeholder="Stage"
                  :class="[api_param.pipeline_stage_id ? `option-selected` : '',]"
                  popper-class="automation-rule-stage-select-dropdown"
                  @change="changeSelectWidth('automation-rule-stage-select','automation-rule-stage-select-dropdown')"
                >
                  <el-option
                    class="select-primary"
                    v-for="(stage , index) in response.stages"
                    :key="index+'stage_pipeline'"
                    :label="stage.title"
                    :value="stage.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <span v-else class="m-0 then-do-this">Then do this</span>
            </div>
          </div>
          <div :class="{ 
                        'd-none' : !isSurgeryRulesSelected,
                        'd-flex flex-column' : isSurgeryRulesSelected,
                        'pipeline-automation-border': isSurgeryRulesSelected,
                        'active' : ruleActive(pipelineAutomationRuleConstants.SURGERY_DATE_ADDED)
                       }"
                 @click="clickRule(pipelineAutomationRuleConstants.SURGERY_DATE_ADDED)">
            <div class="text-break d-flex align-items-center">
              <div class="">
                <el-radio :label="pipelineAutomationRuleConstants.SURGERY_DATE_ADDED" v-model="api_param.rule_type" class="m-0 pipeline-automation-radio" v-if="isSurgeryRulesSelected" fill="#2f597a">&#8203;</el-radio>
                <span class="mr-2" v-if="api_param.rule_type">If</span>
                <el-select
                  class="select-primary automation-rule-select"
                  :class="[api_param.rule_type ? `option-selected` : '',]"
                  popper-class="automation-rule-select-dropdown"
                  v-model=api_param.rule_type
                  placeholder="If this happens"
                  @change="changeSelectWidth('automation-rule-select','automation-rule-select-dropdown')"
                  v-if="![pipelineAutomationRuleConstants.SURGERY_DATE].includes(api_param.rule_type)"
                >
                  <el-option
                    class="select-primary"
                    v-for="(rule_type , index) in surgery_added_rule_types_filtered"
                    :key="index+'rule_type_2'"
                    :label="api_param.rule_type && api_param.rule_type === rule_type.value ? rule_type.label2 : rule_type.label"
                    :value="rule_type.value"
                  >
                  </el-option>
                </el-select>
                <strong v-else class="pipeline-automation-rule-label"> {{ selectedRuleTypeLabel }} </strong>
                <span>is added</span>

              </div>
            </div>

            <img class="my-2" :style="`opacity: ${arrowOpacity}`" src="/img/svg/arrow_down_green.svg" height="21" width="16" alt="image">

            <div>
              <div v-if="ruleActive(pipelineAutomationRuleConstants.SURGERY_DATE_ADDED)">
                <span class="ml-0 my-0 mr-2 d-inline-block">Then move the patient to </span>
                <el-select
                  class="select-primary automation-rule-pipeline-select"
                  v-model=api_param.next_pipeline_id
                  placeholder="Pipeline"
                  :class="[api_param.next_pipeline_id !==null && api_param.next_pipeline_id !==undefined ? `option-selected` : '',]"
                  popper-class="automation-rule-pipeline-select-dropdown"
                  @change="pipelineIndexChange"
                >
                  <el-option
                    class="select-primary"
                    v-for="(pipeline , index) in response.pipelines"
                    :key="index+'next_pipeline'"
                    :label="pipeline.editable_title"
                    :value="index"
                  >
                  </el-option>
                </el-select>
                <span class="mx-2 my-0 d-inline-block"> -- </span>
                <el-select
                  class="select-primary automation-rule-stage-select"
                  v-model=api_param.pipeline_stage_id
                  placeholder="Stage"
                  :class="[api_param.pipeline_stage_id ? `option-selected` : '',]"
                  popper-class="automation-rule-stage-select-dropdown"
                  @change="changeSelectWidth('automation-rule-stage-select','automation-rule-stage-select-dropdown')"
                >
                  <el-option
                    class="select-primary"
                    v-for="(stage , index) in response.stages"
                    :key="index+'stage_pipeline'"
                    :label="stage.title"
                    :value="stage.id"
                  >
                  </el-option>
                </el-select>
                <div class="mt-3">
                  <el-checkbox v-if="ruleActive(pipelineAutomationRuleConstants.SURGERY_DATE_ADDED)" v-model="api_param.apply_to_current">Apply to all patients with a surgery date.</el-checkbox>
                </div>
              </div>
              <span v-else class="m-0 then-do-this">Then do this</span>
            </div>
          </div>
<!--          Email Section-->
<!--          Currently disable-->
          <div v-if="false">
            <el-checkbox fill="#FF6421" class="mt-3 mb-3" v-model="api_param.send_email">Send email notification to patient if this action is triggered.
            </el-checkbox>
            <div v-if="api_param.send_email">
              <h4 class="labels">Email Content</h4>
              <editor
                v-model="api_param.email_content"
                :api-key=editorApiKey
                :init=editorOption
              />
            </div>

          </div>
          <div class="text-right mt-4">
            <el-button class="cancel-btn" @click="handleClose">Cancel</el-button>
            <el-button class="add-btn" native-type="submit" type="submit">{{ submitText }}</el-button>
          </div>
        </form>
    </el-dialog>
  </div>
</template>

<script>
import {constants} from "../../../util/constants";
import SurgeryDateRule from "./PipelineAutomation/SurgeryDateRule";
import ChecklistCompletionRule from "./PipelineAutomation/ChecklistCompletionRule";
import pipelineAutomationRuleMixin from "../../../mixins/pipelineAutomationRuleMixin";
import PipelineStageChecklistsPercentageCompletionRule
  from "./PipelineAutomation/PipelineStageChecklistsPercentageCompletionRule";
import PatientStageDaysRule from "./PipelineAutomation/PatientStageDaysRule";
import { Radio } from "element-ui";

export default {
  components: {
    PatientStageDaysRule,
    PipelineStageChecklistsPercentageCompletionRule,
    ChecklistCompletionRule, SurgeryDateRule, [Radio.name] : Radio},
  mixins:[pipelineAutomationRuleMixin],
  props: {
    pipelineId: {
      type: Number,
      default: null,
      description: 'pipeline id'
    },
    addPipelineAutomationRuleDialogVisible: {
      type: Boolean,
      default: false,
      description: 'Bool for dialog visibility'
    }
  },
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: this.$store.state.toolbarOptionsWith250Height,
      surgeryRuleApiParams:{
        date_duration: null,
        date_count: null,
      },
      checklistCompletionRuleApiParams:{
        checklist_name: null,
        temp_stage: null,
        completed_by:1
      },
      pipelineStageCompletionRuleApiParams:{
        current_stage_id:null,
        current_stage_type:null,
        percentage:null
      },
      patientStageDaysRuleApiParams:{
        date_count: null,
        current_stage_id : null
      },
      api_param: {
        id:null,
        rule_type: null,
        send_email: false,
        email_content: null,
        pipeline_stage_id: null,
        next_pipeline_id: null,
        date_duration: null,
        date_count: null,
        checklist_name: null,
        temp_stage: null,
        percentage: null,
        completed_by: 1,
        current_stage_id:null,
        current_stage_type:null,
        apply_to_current: false,
      },
      rule_types: this.$store.getters['pipelineModule/getAutomationRuleType'],
      response: {
        checklists: [],
        stages: [],
        pipelines: [],
      },
      loaders: {
        modal: false
      },
      firstTimeOpened: true,
      selectStyling: [
        {className: 'automation-rule-select', width: 103},
        {className: 'automation-rule-stage-select', width: 40},
        {className: 'automation-rule-pipeline-select', width: 54},
        {className: 'date-duration', width: 60},
        {className: 'check-list', width: 95},
        {className: 'check-list-temp-stage', width: 108},
        {className: 'checklist-percentage-pipeline-stage', width: 108},
        {className: 'current-stage-type', width: 80},
        {className: 'checklist-percentage', width: 77}
      ],
      editIncludedChecklist: undefined,
      editIncludedStage: undefined,
      editPipelineRule: false,
      dropdowns: {
        stages: this.$store.state.pipelineModule.tempStages
      },
      pipelineAutomationRuleConstants: constants.pipeline.automationRule,
      rule_types_filtered: this.$store.getters['pipelineModule/getAutomationRuleType'].filter((el) => ![constants.pipeline.automationRule.SURGERY_DATE_ADDED].includes(el.value)),
      surgery_added_rule_types_filtered: this.$store.getters['pipelineModule/getAutomationRuleType'].filter((el) => ![constants.pipeline.automationRule.SURGERY_DATE].includes(el.value))
    };
  },
  computed: {
    isSurgeryRulesSelected() {
      return [this.pipelineAutomationRuleConstants.SURGERY_DATE, this.pipelineAutomationRuleConstants.SURGERY_DATE_ADDED].includes(this.api_param.rule_type);
    },
    showOnOldRules() {
      let constants = this.pipelineAutomationRuleConstants;
      let selectedRule = this.api_param.rule_type;
      return selectedRule && [constants.SURGERY_DATE, constants.CHECKLIST, constants.PIPELINE_STAGE_CHECKLIST_PERCENTAGE_COMPLETION, constants.PATIENT_STAGE_DAYS].includes(selectedRule)
    },
    selectedRuleTypeLabel() {
      let vm = this;
      let rule_type = vm.rule_types.find((el) => el.value == vm.api_param.rule_type);
      return rule_type?.label2 || '' 
    },
    arrowOpacity() {
      return this.api_param.rule_type ? 1 : 0.3;
    },
    submitText() {
      return this.editPipelineRule ? 'Update':'Add Automation';
    },
    titleText() {
      return this.editPipelineRule ? 'Edit Pipeline Automation' : 'Pipeline Automation'
    },
    refreshListing() {
      return this.$store.getters['pipelineModule/getRefreshPipelineAutomationRuleListing']
    },
    filteredChecklists() {
      let checklists = this.response.checklists.filter((checklist) => {
        return checklist.temp_stage === this.api_param.temp_stage;
      })
      return [...new Map(checklists.map(item =>
        [item['description'], item])).values()];
    },
    currentPipelineStages(){
      if(this.pipelineId && this.response.pipelines && this.response.pipelines.length>0){
        let index = this.response.pipelines.findIndex(pipeline=>pipeline.id == this.pipelineId);
        return this.response.pipelines[index].stages
      }
      return [];
    },

    currentPipelineStagesForPatientStageDaysRule() {
      let alreadyUsedStages = [];
      this.$parent.response.pipelineAutomationRuleList.forEach((rule) => {
        if (rule.rule_type == constants.pipeline.automationRule.PATIENT_STAGE_DAYS) {
          alreadyUsedStages.push(rule.current_stage_id);
        }
      })
      if (this.pipelineId && this.response.pipelines && this.response.pipelines.length > 0) {
        let index = this.response.pipelines.findIndex(pipeline => pipeline.id == this.pipelineId);
        let stages = this.response.pipelines[index].stages.filter(item => alreadyUsedStages.indexOf(item.id) === -1);
        return stages;
      }
      return [];
    },

    checklistCompletionRuleApiParamsToBeWatched () {
      return Object.assign({}, this.checklistCompletionRuleApiParams)
    }
  },
  watch: {
    'refreshListing': {
      handler(newVal) {
        if (newVal) {
          this.firstTimeOpened = true;
        }
      }
    },
    'api_param.rule_type': {
      handler(val) {
        this.resetRuleApiParams();
      }
    },
    'addPipelineAutomationRuleDialogVisible': {
      handler(val) {
        if (val && this.firstTimeOpened) {
          this.firstTimeOpened = false
          this.getPipelineStages();
          this.getNewTodoSettingList();
        }
      }
    },
    'api_param.next_pipeline_id': {
      handler(val) {
        if (this.response.pipelines.length > 0 && this.response.pipelines[val])
          this.response.stages = this.response.pipelines[val].stages;
      }
    },
    'surgeryRuleApiParams': {
      handler(val) {
        this.api_param.date_count = this.surgeryRuleApiParams.date_count;
        this.api_param.date_duration = this.surgeryRuleApiParams.date_duration;
      }, deep: true
    },
    'checklistCompletionRuleApiParamsToBeWatched': {
      handler(newVal,oldVal) {
        if(oldVal.temp_stage != null && oldVal.temp_stage !== '' && oldVal.temp_stage !== newVal.temp_stage){
          this.checklistCompletionRuleApiParams.checklist_name = null;
          this.changeSelectWidthDefault('check-list',95);
        }
        this.api_param.temp_stage = newVal.temp_stage;
        this.api_param.checklist_name = newVal.checklist_name;
        this.api_param.completed_by = newVal.completed_by;
      }, deep: true
    },
    'pipelineStageCompletionRuleApiParams': {
      handler(val) {
        this.api_param.current_stage_id = this.pipelineStageCompletionRuleApiParams.current_stage_id;
        this.api_param.current_stage_type = this.pipelineStageCompletionRuleApiParams.current_stage_type;
        this.api_param.percentage = this.pipelineStageCompletionRuleApiParams.percentage;
      }, deep: true
    },
    'patientStageDaysRuleApiParams': {
      handler(val) {
        this.api_param.date_count = this.patientStageDaysRuleApiParams.date_count;
        this.api_param.current_stage_id = this.patientStageDaysRuleApiParams.current_stage_id;
      }, deep: true
    },
  },

  methods: {
    resetFields() {
      let vm = this;
      if (
        this.editPipelineRule &&
        this.editIncludedChecklist &&
        this.editIncludedChecklist.temp_stage === this.checklistCompletionRuleApiParams.temp_stage &&
        this.editIncludedChecklist.description === this.checklistCompletionRuleApiParams.checklist_name
      ) {
        this.response.checklists = this.response.checklists.filter((checklist) => {
          if (checklist.temp_stage == vm.editIncludedChecklist.temp_stage && vm.editIncludedChecklist.description == checklist.description) {
            return false;
          }
          return true;
        });
      }
      this.api_param.rule_type = null; // See watcher on this, resetRuleApiParams function is called to clear remaining fields
      this.api_param.send_email = false;
      this.api_param.apply_to_current = false;
      this.api_param.email_content = null;
      this.api_param.pipeline_stage_id = null;
      this.api_param.next_pipeline_id = null;
      this.response.stages = [];

      this.editPipelineRule = false;
      this.editIncludedChecklist = undefined;
      this.editIncludedStage = undefined;
    },

    resetRuleApiParams() {
      this.checklistCompletionRuleApiParams.temp_stage = null;
      this.checklistCompletionRuleApiParams.checklist_name = null;
      this.checklistCompletionRuleApiParams.completed_by = 1;
      this.surgeryRuleApiParams.date_count = null;
      this.surgeryRuleApiParams.date_duration = null;
      this.pipelineStageCompletionRuleApiParams.current_stage_id = null;
      this.pipelineStageCompletionRuleApiParams.current_stage_type = null;
      this.pipelineStageCompletionRuleApiParams.percentage = null;
      this.patientStageDaysRuleApiParams.date_count = null;
      this.patientStageDaysRuleApiParams.current_stage_id = null;
      this.api_param.pipeline_stage_id = null;
      this.api_param.next_pipeline_id = null;
      this.resetSelectElementsWidth();
    },

    resetSelectElementsWidth(){
      this.selectStyling.forEach((item, index) => {
        if (index > 2) {
          this.changeSelectWidthDefault(item.className, item.width);
        }
      })
    },

    setSelectElementsWidth(){
      this.selectStyling.forEach((item, index) => {
        this.changeSelectWidth(item.className, item.className + '-dropdown');
      })
    },

    handleClose() {
      this.resetFields();
      this.selectStyling.forEach(item => {
        this.changeSelectWidthDefault(item.className, item.width);
      })
      this.$emit('closeAddPipelineAutomationRuleDialog')
    },

    pipelineIndexChange(){
      this.api_param.pipeline_stage_id = null;
      this.changeSelectWidth('automation-rule-pipeline-select','automation-rule-pipeline-select-dropdown')
    },

    getPipelineStages() {
      let vm = this;
      let params = {
        pipeline_id: this.pipelineId
      }

      vm.$store.dispatch('pipelineModule/_getPipelineStages', params).then(response => {
        vm.response.pipelines = response.data.data.data;
      }).catch(err => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Pipeline stages Retrieval',
          message: message
        });
      });
    },

    getNewTodoSettingList() {
      let params = {
        pipeline_id: this.pipelineId
      }

      this.$store.dispatch('pipelineModule/_getNewTodoSettingList', params).then(response => {
        this.response.checklists.push(...response.data.data);
      }).catch(err => {
      });
    },

    showError(message) {
      this.$notify.error({
        title: 'Error',
        message: message
      });
    },

    showSuccess(message, title = "Success") {
      this.$notify.success({
        title: title,
        message: message
      });
    },

    validateParams(params) {
      let message = "";
      if (!params.rule_type) message = "Rule Type is required";
      else if (!params.pipeline_stage_id) message = "Stage field is required";
      else if (params.send_email && !params.email_content) message = "Email Content is required";
      else if (params.rule_type === constants.pipeline.automationRule.SURGERY_DATE) {
        if (!params.days_count || params.days_count === 0) message = "Count and duration type is required";
        if (isNaN(params.days_count)) message = "Count must be numeric";
      } else if (params.rule_type === constants.pipeline.automationRule.CHECKLIST) {
        if (!params.new_todo_setting_description) message = "Checklist is required";
      } else if (params.rule_type === constants.pipeline.automationRule.PIPELINE_STAGE_CHECKLIST_PERCENTAGE_COMPLETION) {
        if (!params.percentage) message = "Percentage is required";
        if (params.current_stage_type != 1 && !params.current_stage_id) message = "Current Stage is required";
        if (!params.current_stage_type) message = "Stage Type is required";
      } else if (params.rule_type === constants.pipeline.automationRule.PATIENT_STAGE_DAYS) {
        if (!params.current_stage_id) message = "Pipeline Stage is required";
        if (!params.days_count || params.days_count === 0) message = "Days count is required";
      }
      if (message !== "") {
        this.showError(message);
        return false;
      }
      return true;
    },

    prepareSubmitParams() {
      let params = {
        rule_type: this.api_param.rule_type,
        pipeline_id: this.pipelineId,
        send_email: this.api_param.send_email,
        email_content: this.api_param.email_content,
        pipeline_stage_id: this.api_param.pipeline_stage_id,
        new_todo_setting_description: this.api_param.checklist_name,
        days_count: null,
        temp_stage: this.api_param.temp_stage,
        percentage: this.api_param.percentage,
        completed_by: this.api_param.completed_by,
        current_stage_id: this.api_param.current_stage_id,
        current_stage_type: this.api_param.current_stage_type,
        apply_to_current: this.api_param.apply_to_current
      }
      if(this.editPipelineRule) params['id'] = this.api_param.id;
      if (this.api_param.rule_type === constants.pipeline.automationRule.SURGERY_DATE) {
        let daysCount = this.api_param.date_count;
        if (this.api_param.date_duration === 2) daysCount *= 7;
        else if (this.api_param.date_duration === 3) daysCount *= 30;
        else if (this.api_param.date_duration === 4) daysCount *= 365;
        params['days_count'] = this.api_param.date_duration ? daysCount : 0;
      }
      if (this.api_param.rule_type === constants.pipeline.automationRule.PATIENT_STAGE_DAYS) {
        let daysCount = this.api_param.date_count;
        params['days_count'] = daysCount ? daysCount : 0;
      }
      return params;
    },

    validateDuplicity(rule) {
      let flag = true;
      this.$parent.response.pipelineAutomationRuleList.forEach((item) => {
        if (rule.id && rule.id == item.id) {
          return;
        }
        if (
          item.rule_type == rule.rule_type
          && item.pipeline_id == rule.pipeline_id
          // && item.pipeline_stage_id == rule.pipeline_stage_id
          && item.new_todo_setting_description == rule.new_todo_setting_description
          && item.completed_by == rule.completed_by
          && item.temp_stage == rule.temp_stage
          && item.days_count == rule.days_count
          && item.percentage == rule.percentage
          && item.current_stage_type == rule.current_stage_type
          && item.current_stage_id == rule.current_stage_id
          ) {
          if (rule.id == undefined) {
            this.showError("This rule is already exist.");
          }
          flag = false;
          return;
        }
      })
      return flag
    },

    addChecklistInChecklists(checklist){
      let checklistIndex = this.response.checklists.findIndex(item => item.description === checklist.description && item.temp_stage === checklist.tempStage);
      if (checklistIndex === -1) {
        this.response.checklists.push(checklist)
        this.response.checklists.sort((a, b)=>a.description.localeCompare(b.description));
        this.$set(this.response.checklists)
      }
    },

    onSubmit() {
      let vm = this;
      let params = vm.prepareSubmitParams();
      if (!vm.validateParams(params)) return;

      let check = vm.validateDuplicity(params);
      if (!check) {
        this.handleClose();
        return;
      }
      vm.loaders.modal = true
      let apiFunc = this.editPipelineRule ? '_updateAutomationRule' : '_storeAutomationRule';
      vm.$store.dispatch(`pipelineModule/${apiFunc}`, params).then(response => {
        vm.showSuccess(response.data.message, 'Pipeline Automation Rule');
        vm.$emit('updatePipelineAutomationRuleList', response.data.data);
        let index = vm.response.checklists.findIndex(item => item.description == vm.api_param.checklist_name && item.temp_stage == vm.api_param.temp_stage);
        if (index !== -1) {
          vm.response.checklists.splice(index, 1);
        }
        vm.handleClose();
      }).catch(err => {
        vm.showError(err?.response?.data?.message || 'Some error has occurred please try again later.');
      }).finally(() => {
        vm.loaders.modal = false;
      });
    },

    editPipelineAutomationRule(rule) {
      let vm = this;
      vm.loaders.modal = true;
      vm.editPipelineRule = true;
      /*setTimeout is used as we need pipeline_stages this is for wait of api response*/
      setTimeout(function () {
        vm.api_param.rule_type = rule.rule_type;
        /*There is watcher in api_param.rule_type in which when rule type changed all the params are go for reset*/
        /*Used to achieve synchronise behaviour*/
        vm.$nextTick(()=>{
          vm.api_param.id = rule.id;
          vm.api_param.send_email = rule.send_email;
          vm.api_param.email_content = rule.email_content;

          vm.response.pipelines.forEach((pipeline, index) => {
            pipeline.stages.forEach(stage => {
              if (stage.id == rule.pipeline_stage_id) {
                vm.api_param.next_pipeline_id = index;
                vm.$nextTick(() => {
                  vm.api_param.pipeline_stage_id = rule.pipeline_stage_id;
                  if (rule.rule_type == constants.pipeline.automationRule.PIPELINE_STAGE_CHECKLIST_PERCENTAGE_COMPLETION){
                    vm.pipelineStageCompletionRuleApiParams.current_stage_id = rule.current_stage_id;
                  }else if(rule.rule_type == constants.pipeline.automationRule.PATIENT_STAGE_DAYS){
                    vm.patientStageDaysRuleApiParams.current_stage_id = rule.current_stage_id;
                  }
                })
              }
            })
          })

          if (rule.rule_type == constants.pipeline.automationRule.SURGERY_DATE) {
            let daysCount = rule.days_count;
            if (daysCount % 365 == 0) {
              vm.surgeryRuleApiParams.date_duration = constants.pipeline.surgeryDateDuration.YEARLY;
              vm.surgeryRuleApiParams.date_count = daysCount / 365;
            } else if (daysCount % 30 == 0) {
              vm.surgeryRuleApiParams.date_duration = constants.pipeline.surgeryDateDuration.MONTHLY;
              vm.surgeryRuleApiParams.date_count = daysCount / 30;
            } else if (daysCount % 7 == 0) {
              vm.surgeryRuleApiParams.date_duration = constants.pipeline.surgeryDateDuration.WEEKLY;
              vm.surgeryRuleApiParams.date_count = daysCount / 7;
            } else {
              vm.surgeryRuleApiParams.date_duration = constants.pipeline.surgeryDateDuration.DAILY;
              vm.surgeryRuleApiParams.date_count = daysCount;
            }
          } else if (rule.rule_type == constants.pipeline.automationRule.CHECKLIST) {
            let checklist = {id: 0, description: rule.new_todo_setting_description, temp_stage: rule.temp_stage}
            vm.editIncludedChecklist = checklist;
            vm.response.checklists.push(checklist);
            // vm.sortArrayOfObjectByKey();
            vm.checklistCompletionRuleApiParams.checklist_name = rule.new_todo_setting_description;
            vm.checklistCompletionRuleApiParams.temp_stage = rule.temp_stage;
            vm.checklistCompletionRuleApiParams.completed_by = rule.completed_by;
          } else if (rule.rule_type == constants.pipeline.automationRule.PIPELINE_STAGE_CHECKLIST_PERCENTAGE_COMPLETION) {
            vm.pipelineStageCompletionRuleApiParams.current_stage_type = rule.current_stage_type;
            vm.pipelineStageCompletionRuleApiParams.percentage = rule.percentage;
          } else if(rule.rule_type == constants.pipeline.automationRule.PATIENT_STAGE_DAYS){
            let daysCount = rule.days_count;
            vm.patientStageDaysRuleApiParams.date_count = daysCount;
            if (vm.currentPipelineStages && vm.currentPipelineStages.length > 0) {
              let index = vm.currentPipelineStages.findIndex(item => item.id == rule.current_stage_id);
              if (index > -1) {
                vm.editIncludedStage = vm.currentPipelineStages[index];
              }
            }

          }
          setTimeout(function (){
            vm.setSelectElementsWidth();
          },100)
          vm.loaders.modal = false;
        })
      }, 5500);
    },

    sortArrayOfObjectByKey(){
      this.response.checklists.sort(function (a, b) {
        let keyA = a.description,
          keyB = b.description;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    },
    clickRule(rule)
    {
      if (this.api_param.rule_type != rule && this.isSurgeryRulesSelected) {
        this.api_param.rule_type = rule
        this.changeSelectWidth('automation-rule-select','automation-rule-select-dropdown');
      };
    },
    ruleActive(rule) {
      return this.api_param.rule_type == rule
    },
  }
};
</script>

<style lang="scss">
.pipeline-automation-rule-dialog {
  input {
    text-align: left;
    font: normal normal 600 20px/27px Open Sans;
    letter-spacing: 0;
    color: #32325D;
    opacity: 1;
  }

  .el-checkbox {
    .el-checkbox__label {
      color: #172B4D;
      font: normal normal normal 14px/19px Open Sans;
    }
  }

  .el-select {
    .el-input {
      .el-input__inner {
        height: 29px;
        border: none !important;
        border-bottom: 1px solid !important;
        border-radius: 0;
        padding: 0;
        border-color: #BDC4CC !important;;
      }

      .el-input__suffix .el-input__suffix-inner {
        .el-select__caret::after, .el-select__caret::before {
          content: "";
        }
      }
    }
  }

  .el-select.automation-rule-select {
    .el-input {
      .el-input__inner {
        width: 103px;
      }
    }
  }

  .el-select.automation-rule-stage-select {
    .el-input {
      .el-input__inner {
        width: 38px;
      }
    }
  }

  .el-select.automation-rule-pipeline-select {
    .el-input {
      .el-input__inner {
        width: 54px;
      }
    }
  }

  .el-select.option-selected {
    .el-input {
      .el-input__inner {
        width: auto;
        border-bottom: none !important;
      }
    }
  }
  .pipeline-automation-border {
    border: 1px solid rgba(0, 0, 0, 0.144);
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem 0;
    cursor: pointer;
    &.active {
      border-color: #2f597a !important;
    }
    .pipeline-automation-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner{
            border-color: #2f597a;
            background-color: #2f597a;
          }
        }
      }
    }
    .pipeline-automation-rule-label{
        text-align: left;
        font: normal normal 600 0.875rem/27px Open Sans;
        letter-spacing: 0;
        color: #32325D;
        opacity: 1;
    }
  }
  span {
    &.then-do-this {
      color: #BDC4CC;
      font: normal normal 600 14px/19px Open Sans;
    }
  }
}
</style>

<style scoped>
.cancel-btn {
  top: 505px;
  left: 756px;
  width: 79px;
  height: 45px;
  background: #8898AA 0 0 no-repeat padding-box;
  -webkit-box-shadow: 0 2px 8px #32325d26;
  box-shadow: 0 2px 8px #32325d26;
  border: 0;
  border-radius: 6px;
  color: white;
  opacity: 1;
}

.add-btn {
  top: 505px;
  left: 850px;
  width: 183px;
  height: 45px;
  background: #832C44 0 0 no-repeat padding-box;
  -webkit-box-shadow: 0 2px 8px #32325d26;
  box-shadow: 0 2px 8px #32325d26;
  border-radius: 6px;
  border: 0;
  color: white;
  opacity: 1;
}

.labels {
  color: #172B4D;
  font: normal normal normal 14px/19px Open Sans;
}
</style>
