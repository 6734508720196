<template>
  <div class="edit-patient mt-2 pb-4 ml-3 mr-3">

    <!-- Top Navigation -->
    <div class="ml-4">
      <div class="back-button-container">
        <div class="router-link" @click="$router.back()">
          <div class="d-inline-block">
            <img class="d-inline-block mt--1" src="/img/back_arrow.svg" alt="">
            <h3 class="text-white mb-0 ml-2 d-inline-block">Back</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Navigation Finished-->

    <!--  Edit Patient  -->
    <div class="row header-row ml-0 mr-0 mb-2 pl-3 pr-3 pt-3">
      <h1 class="font-weight-600">Add Patient</h1>
    </div>
    <!--  Edit Patient Complete  -->
    <validation-observer v-slot="{handleSubmit}" ref="addPatientRef">
      <form class="add-patient" role="form" @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <card type="default" header-classes="bg-transparent" v-loading="loader">

            <div class="scrollable ">
              <div class="row mr-0">
                <div class="col-md-6">
                  <base-input
                    :rules="{required: true}"
                    name="first name"
                    label="First Name*"
                    placeholder="First Name"
                    v-model="form.first_name"
                  />
                </div>

                <div class="col-md-6">
                  <base-input
                    :rules="{required: true}"
                    name="last name"
                    label="Last Name*"
                    placeholder="Last Name"
                    v-model="form.last_name"
                  />
                </div>
              </div>

              <div class="row mr-0">
                <div class="col-md-6">
                  <base-input
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Email"
                    v-model="form.email"
                  />
                </div>

                <div class="col-md-6">
                  <base-input
                    @input="acceptNumber"
                    name="phone"
                    label="Phone"
                    placeholder="123-456-7890"
                    v-model="form.phone"
                  />
                </div>
              </div>

              <div class="row mr-0">
                <div class="col-md-6">
                  <p class="mb-2 font-weight-600 custom-label">Height*</p>
                  <div class="row">
                    <div class="col-xs-12 col-md-6">
                      <base-input name="feet" :rules="{required: true}">
                        <el-select
                          prop="feet"
                          class="w-100 select-primary pagination-select"
                          v-model="form.feet"
                          :rules="{required: true}"
                          name="feet"
                          placeholder="FEET"
                        >
                          <el-option
                            class="select-primary"
                            v-for="item in dropdowns.feet"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <base-input name="inches" :rules="{required: true}">
                        <el-select
                          prop="inches"
                          class="w-100 select-primary pagination-select"
                          v-model="form.inches"
                          :rules="{required: true}" name="inches"
                          placeholder="INCHES"
                        >
                          <el-option
                            class="select-primary"
                            v-for="item in dropdowns.inches"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <p class="mb-2 font-weight-600 custom-label">DOB*</p>
                  <base-input name="dob" :rules="{required: true}">
                    <div>
                      <b-input-group class="mb-3">
                        <el-input
                          style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                          v-mask="'##/##/####'"
                          v-model="form.dob"
                          type="text"
                          placeholder="MM/DD/YYYY"
                          autocomplete="off"
                          @blur="handleDob"
                        ></el-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            min="1930-01-01"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="example-input"
                            :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                            @context="onContextDob"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </base-input>
                </div>
              </div>

              <div class="row mr-0 mt-2">
                <div class="col-xs-12 col-md-6 mb-3">
                  <label class="form-control-label">Stage</label>
                  <el-select
                    class="w-100 select-primary pagination-select"
                    v-model="form.stage"
                    placeholder="Initial Stage"
                    @change="stageChanged"
                  >
                    <template v-for="(item,index) in dropdowns.stages">
                      <el-option
                        v-if="index!==0"
                        class="select-primary"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </template>

                  </el-select>
                </div>

                <div class="col-xs-12 col-md-6 mb-3">
                  <p class="mb-2 font-weight-600 custom-label">Surgery Date</p>
                  <div>
                    <b-input-group class="mb-3">
                      <el-input
                        style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                        v-mask="'##/##/####'"
                        id="example-input"
                        v-model="form.surgery_date"
                        type="text"
                        placeholder="MM/DD/YYYY"
                        autocomplete="off"
                        @blur="handleSurgeryDate"
                        @change="surgeryDateChange"
                      ></el-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          locale="en-US"
                          aria-controls="example-input"
                          :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                          @context="onContextSurgeryDate"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>

                <div class="col-xs-12 col-md-6">
                  <base-input
                    label="MRN Number (Optional)"
                    placeholder="MRN Number (Optional)"
                    v-model="form.mrn_number"
                    :maxlength="16"
                  />
                </div>

              </div>
            </div>
          </card>

          <card type="default" header-classes="bg-transparent">
            <h4 class="edit-patient-card-headings text-uppercase text-gray-400 font-weight-400 mb-4">Patient Tags</h4>
            <div class="row">
              <div v-for="(tag,index) in tags" class=" col-xs-12 col-md-6">

                <p class="mb-2 font-weight-600 custom-label ellipsis-overflow-text">{{ tag.name }}</p>
                <el-select
                  class="w-100"
                  v-model="formState[`${tag.id}`]"
                  placeholder="Select a Tag Name"
                  @change="prepareForm"
                  :clearable="true"
                  @clear="removeState(tag.id)"
                >

                  <el-option
                    class="select-primary"
                    v-for="(item) in tag.user_sub_categories"
                    :key="item.name+item.id"
                    :label="item.name"
                    :value="item.id.toString()"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </card>
          <card type="default" header-classes="bg-transparent">
            <h4 class="edit-patient-card-headings text-gray-400 font-weight-400 mb-4">PIPELINE STAGE</h4>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Pipeline</label>
                <base-input name="pipeline">
                  <el-select
                    class="w-100"
                    v-model="form.pipelineId"
                    placeholder="Select"
                  >
                    <el-option
                      class="select-primary"
                      :key="0"
                      label="Select Pipeline"
                      :value="0"
                      :disabled="true"
                    >
                    </el-option>
                    <el-option
                      class="select-primary"
                      v-for="item in pipelines"
                      :key="item.id"
                      :label="item.editable_title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </card>
          <div class=" justify-content-between align-items-center mt-5">
            <div class="">
              <el-button class="cancel-btn" @click="handleClose">Cancel</el-button>
              <el-button class="add_patient_btn" native-type="submit" type="submit">Add Patient</el-button>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import BasicInfoCard from "@/views/Components/Patients/BasicInfoCard";
import SurgeryCard from "@/views/Components/Patients/SurgeryCard";
import WeightCard from "@/views/Components/Patients/WeightCard";
import CategoryCard from "@/views/Components/Patients/CategoryCard";
import moment from "moment";
import swal from "sweetalert2";
import patientConstants from "@/constants/patientConstants";
import pickerOptions from "@/constants/pickerOptions";
import Helper from "@/util/globalHelpers";

export default {
  name: "AddPatient",
  components: {BackButton, CategoryCard, WeightCard, SurgeryCard, BasicInfoCard},
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      loader: false,
      formState: {},
      tags: {},
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        feet: '',
        inches: '',
        dob: '',
        stage: '50',
        surgery_date: '',
        mrn_number: '',
        subCategories: [],
        sendEmail: false,
        pipelineId: 0,
      },
      dropdowns: {
        stages: patientConstants.stages,
        feet: patientConstants.feet,
        inches: patientConstants.inches
      },
      pickerOptions: {
        dobPickerOptions: pickerOptions.dobPickerOptions,
        surgeryDateOptions: pickerOptions.surgeryDateOptions,
      },
      pipelines: [],
    }
  },
  computed: {
    getCheckboxState() {
      return this.form.email.length <= 0;
    },
    categoriesStateEmpty() {
      return (this.tags &&
        Object.keys(this.tags).length === 0 &&
        this.tags.constructor === Object) ||
        this.tags.length <= 0
    },
  },
  watch: {
    dialogVisible() {
      if (this.$refs.addPatientRef) {
        this.$refs.addPatientRef.reset();
      }
    },
  },
  created() {
    this.fetchTags();
    this.fetchPipelines();
  },
  methods: {
    /**
     * Accept and format number in 999-999-9999
     */
    acceptNumber() {
      this.form.phone = Helper.formatPhoneNumber(this.form.phone)
    },

    /**
     * Fetch Pipelines with stages
     */
    fetchPipelines() {
      let vm = this

      //Condition to check if pipelines already exists
      if (!this.$store.state.pipelines.length) {
        vm.loader = true
        vm.$store.dispatch('getPipelinesWithStagesAction')
        .then(response => {
          vm.pipelines = response.data.data.data
          vm.updatePipelineForStageChange()
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Pipelines',
            message: message
          });
        })
        .finally(() => {
          vm.loader = false
        })
      } else {
        vm.pipelines = [...this.$store.getters.getPipelines]
        vm.updatePipelineForStageChange()
      }
    },

    /**
     * Notify error on false stage and surgery date
     * @param stage
     * @param message
     */
    notifyError(stage, message) {
      this.$notify.error({
        title: 'Patient Stage',
        message: message
      })
      this.form.stage = stage
    },

    /**
     * Trigger when state is changed and handle according to surgery date
     */
    stageChanged() {
      let vm = this
      if (vm.form.surgery_date && moment(vm.form.surgery_date) < moment() && (vm.form.stage == '25' || vm.form.stage == '50')) {
        vm.notifyError('75', 'Your surgery date is already passed. Change your surgery date to the ' +
          'future if you\'d like to be Pre-Op or Considering Surgery.')
      } else if (vm.form.surgery_date && moment(vm.form.surgery_date) > moment() && vm.form.stage == '75') {
        vm.notifyError('50', 'Your surgery date is in the future. Please change your surgery date to ' +
          'the past if you\'d like to be Post-Op.')
      }
      vm.updatePipelineForStageChange()
    },

    /**
     * Update Pipeline according to selected Stage
     */
    updatePipelineForStageChange() {
      const stage = patientConstants.stages.filter(stage => {
        return stage.value == this.form.stage
      });
      this.pipelines.forEach(pipeline => {
        //Condition to check if stage and pipeline title is same
        if (pipeline.title.toLowerCase() == stage[0].label.toLowerCase()) {
          this.form.pipelineId = pipeline.id
        }

        //Condition for checking considering surgery stage
        if (pipeline.title == 'Pre-Op' && stage[0].label == 'Considering Surgery') {
          this.form.pipelineId = pipeline.id
        }
      })
    },

    /**
     * Trigger when surgery date is changed and handle according to stage
     */
    surgeryDateChange() {
      let vm = this
      if (this.form.surgery_date && moment(this.form.surgery_date) < moment() && (this.form.stage == '25' || this.form.stage == '50')) {
        this.form.stage = '75'
      } else if (vm.form.surgery_date && moment(vm.form.surgery_date) > moment() && vm.form.stage == '75') {
        this.form.stage = '50'
      }
      vm.updatePipelineForStageChange()
    },

    handleDob(e) {
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date') {
        this.form.dob = '';
      }
      if (moment(this.form.dob) < moment('01/01/1930')) {
        this.form.dob = '01/01/1930';
      }
    },
    fetchTags() {
      axios.get(`${this.$store.getters.getBaseUrl}/api/user-category/1`)
        .then((response) => {
          this.tags = response.data.data
        })
        .catch((error) => console.error(error.response.message))
        .finally(() => {
          this.loaders.tags = false
          this.getPatient();
        })
    },
    handleSurgeryDate(e) {
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date') {
        this.form.surgery_date = '';
      }
    },
    onContextDob(ctx) {
      if (ctx.selectedYMD != '') {
        this.form.dob = ctx.selectedFormatted
      }
    },
    onContextSurgeryDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.form.surgery_date = ctx.selectedFormatted
        this.surgeryDateChange()
      }
    },
    clearForm() {
      this.form = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        feet: '',
        inches: '',
        dob: '',
        stage: '50',
        surgery_date: '',
        mrn_number: '',
        sendEmail: false,
        subCategories: [],
        pipelineId: 0,
      };
      this.formState = {};
    },
    removeState(id) {
      delete this.formState[id]
      this.prepareForm()
    },
    prepareForm() {
      const subCategoryKeys = [
        'user_category_id', 'user_sub_category_id',
        'user_sub_category_name', 'user_category_name'
      ]
      const keys = Object.keys(this.formState)
      const count = keys.length
      const newSubcategoryArray = []
      for (let i = 0; i < count; i++) {
        newSubcategoryArray.push(
          _.zipObject(subCategoryKeys, [...[parseInt(keys[i]),
            parseInt(this.formState[`${keys[i]}`])],
            ...this.getTagNames(keys[i], this.formState[`${keys[i]}`])
          ])
        )
      }
      this.form.subCategories = newSubcategoryArray
    },
    getTagNames(id1, id2) {
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].id === parseInt(id1)) {
          for (let j = 0; j < this.tags[i].sub_category.length; j++) {
            if (this.tags[i].user_sub_categories[j].id === parseInt(id2))
              return [this.tags[i].user_sub_categories[j].name, this.tags[i].name]
          }
        }
      }
      return ['', '']
    },

    async onSubmit() {
      let th = this;

      this.loaders = true;
      this.form.surgery_date = this.form.surgery_date ? moment(this.form.surgery_date).format('YYYY-MM-DD') : '';
      if (moment(this.form.dob) < moment('01/01/1930')) {
        this.form.dob = '01/01/1930';
      }

      if (this.form.email.trim() != '' && !this.form.email.includes('@bariapps.com')) {
        await swal.fire({
          text: 'Do you want to invite this patient to Baritastic via email?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          cancelButtonColor: '#8898AA',
          confirmButtonColor: '#832C44'
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.form.sendEmail = true;
          }
        })
      }

      axios.post(this.$store.getters.getBaseUrl + '/api/patients/create', this.form).then((response) => {
        if (response.data.message == 'duplicate patient added') {
          th.invitePatient();
        } else {
          th.$notify.success({
            title: 'Success',
            message: 'Patient added successfully.'
          });
          th.clearForm();
          th.loaders = false;
          th.$router.push({name: 'ManagePatients'});
        }
      }).catch(error => {
        let message = 'Something went wrong please try again in few minutes.';
        console.log(error.response);
        if (error.response) {
          // let errors = error.response.data.errors;
          // let firstError = Object.keys(errors)[0];
          message = error.response.data.message;
        }
        th.$notify.error({
          title: 'Error',
          message: message
        });
      });
    },

    invitePatient() {
      let vm = this
      swal.fire({
        text: 'It looks like that email address is already being used. If you do not see that patient in your Manage Patient section, then they may have created an account on their own. \n' +
          'Please invite your patient to join your program. You can do that by sending an email invitation or we can send one to them. ',
        showDenyButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Auto Email Patient',
        cancelButtonText: "I'll Do it Myself",
        cancelButtonColor: '#8898AA',
        confirmButtonColor: '#832C44',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          vm.loaders = false;
          axios.get(this.$store.getters.getBaseUrl + '/api/invite-patient-email/' + this.form.email)
            .then((response) => {
              this.$notify.success({
                title: 'Success',
                message: 'Invite Sent.'
              });
              vm.loaders = false;
              vm.$router.push({name: 'ManagePatients'});
            })
            .catch((error) => console.error(error.response.message))
            .finally(() => this.addPatientLoader = false)
        } else if (result.dismiss == 'close') {

        } else {
          vm.loaders = false;
          this.$router.push({name: 'ManagePatients'});
        }
      })
    },
    handleClose(done) {
      this.clearForm();
      this.$router.push({name: 'ManagePatients'});
    },
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-button:focus {
  color: white;
  background-color: #94B527;
}
</style>

<style>
.height-fixed {
  max-height: 480px;
}

.form-label {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

.add_patient_btn, .add_patient_btn:hover, .add_patient_btn:focus {
  top: 505px;
  left: 850px;
  width: 130px;
  height: 45px;
  background: #832C44 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: 0px;
  color: white;
  opacity: 1;
}

.cancel-btn {
  top: 505px;
  left: 756px;
  width: 79px;
  height: 45px;
  background: #8898AA 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border: 0px;
  border-radius: 6px;
  color: white;
  opacity: 1;
}

.custom-label {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.checkbox-input {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  opacity: 1;
}

.edit-patient .edit-patient-card-headings {
  text-align: left;
  font: normal normal normal 17px/23px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  text-transform: uppercase;
  opacity: 1;
}

.patient-detail-form-container .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.patient-detail-form-container .el-form-item__label {
  line-height: 20px;
  font-weight: 500 !important;
  color: black;
}

.patient-detail-form-container .el-form * {
  font: normal normal normal 14px/19px Open Sans;
}

.patient-detail-form-container .el-input.is-active .el-input__inner,
.patient-detail-form-container .el-input__inner:focus,
.patient-detail-form-container .el-select .el-input.is-focus .el-input__inner,
.patient-detail-form-container .el-select .el-input .el-input__inner:focus {
  border-color: #BDC4CC !important;
  box-shadow: 0 3px 2px #E9ECEF0D;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: '*';
  margin-left: 4px;
}

.back-button-container {
  position: absolute;
  top: 28px
}

.router-link {
  cursor: pointer;
}

</style>
