<template>
  <div style="background: #F2F5FC; height: calc(100vh - 77px)">
    <back-button class="ml-4" />
    <div style="position: absolute;top: 28px" class="bg_maroon">
      <router-link class="router-link" :to="{name: 'Pipeline' }">
        <div class="mt--5 ml-4 d-inline-block">
          <img class="d-inline-block mt--1" src="/img/back_arrow.svg" alt="">
          <h3 class="text-white mb-0 ml-2 d-inline-block">Back</h3>
        </div>
      </router-link>
    </div>
    <div class="row justify-content-between align-items-center m-0 pl-4 pt-4 pb-3 pr-2">
      <h1 class="ml-0 mb-0 d-inline-block">Customize Pipeline</h1>

      <base-button class="mr-3 btn-secondary-action button-font title-btn-padding button-hover-effect border-none" @click="openAddPipelineDialog"
                   type="secondary">Add Pipeline
      </base-button>

    </div>
    <div style="width: 100%;margin: auto" class="customize-pipeline-container pl-4 pr-4">
      <el-tabs class="tab_main" type="border-card" v-model="activeTab">
        <el-tab-pane v-for="(pipeline , pipeline_index) in pipelines" :key="`customize_pipeline_tabs${pipeline_index}`" :label="pipeline.editable_title">

          <div>
            <h1 class="ml-0 d-inline-block" v-html="getPipelineTitleForHeading(pipeline)"></h1>

            <base-button style="color: #2F597A; border-color: #2F597A" outline @click="openAddStageDialog(pipeline)"
                         class="float-right mr-2 d-inline-block success_btn border-none" type="success">Add
              Stage
            </base-button>
            <base-button v-if="!isDefaultPipeline(pipeline)" @click="deletePipeline(pipeline,pipeline_index)" outline
                         class="float-right mr-2 d-inline-block border-none" type="danger">Delete
              Pipeline
            </base-button>
            <base-button @click="openEditPipelineDialog(pipeline)" outline
                         class="float-right mr-2 d-inline-block success_btn border-none" type="default">Edit
            </base-button>

            <base-button v-if="!isLostPatient(pipeline.title)" style="color: #2F597A; border-color: #2F597A" outline @click="openEditPipelineValue(pipeline)"
                         class="float-right mr-2 d-inline-block success_btn border-none" type="success">
              Value <i class="fas fa-dollar-sign"></i>{{ addCommaToAmount(pipelineValues[pipeline_index]) }}
            </base-button>

          </div>

          <div class="pt-3" style="padding-bottom: 15px" v-loading='loaders.stageLoader'>
            <draggable group="all-users" ghost-class="moving-card" filter=".action-button" @change="stageOrderChanged"
                       class="w-full max-w-md delete_patient_area list" :list="pipeline.stages" :animation="200">
              <div v-for="(stage , stage_index) in pipeline.stages" :key="`customize_pipeline_tabs_stages${stage_index}`" v-if="stage.title != 'Unassigned'"
                   class="d-inline-block p-1 width_25 pointer">
                <div class="pt-2 pl-3 pb-2 pr-3 h-100 list-content"
                     style="box-shadow: none; border: 1px solid #BDC4CC80;border-radius: 5px">
                  <h4>{{stage.title}}</h4>
                  <div class="pt-2 stage_box">
                    <p class="mb-0 d-inline-block line_height_17"><span style="font-size: 14px">Rotting in (days)</span>
                      <br>
                      <span style="font-size: 12px;font-weight: bold; opacity: 0.7">{{stage.rotting_days}} Days</span>
                    </p>

                    <div class="d-inline-block ">
                      <img class="pointer mr-2" @click="openEditStageDialog(stage)" src="/img/edit.svg" alt="">
                      <img @click="deleteStage(stage,stage_index,pipeline_index)" src="/img/delete.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </div>

          <div style="margin:0px -15px -15px; background: rgb(242, 245, 252);">
            <pipeline-automation-rules-listing
              :ref="`pipelineAutomationRuleListing${pipeline.id}`"
              :pipeline-id="pipeline.id"
              :pipeline-title="pipeline.editable_title"
              @deletePipelineAutomationRule="deletePipelineAutomationRule"
            />
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>

    <add-pipeline-dialog :addPipelineDialogVisible="addPipelineDialogVisible"
                         @closeAddPipelineDialog="addPipelineDialogVisible=false"
                         @updatePipelinesArray="updatePipelinesArray"/>

    <add-stage-dialog :pipeline="pipeline_to_edit" :addStageDialogVisible="addStageDialogVisible"
                      @closeAddStageDialog="addStageDialogVisible=false" @updatePipelinesArray="updatePipelinesArray"/>

    <edit-pipeline-dialog :pipeline="pipeline_to_edit" :editPipelineDialogVisible="editPipelineDialogVisible"
                          @closeEditPipelineDialog="editPipelineDialogVisible=false"
                          @updatePipelinesArray="updatePipelinesArray"/>

    <edit-pipeline-value :pipeline="pipeline_to_edit" :dialog-visibility="dialogVisibility"
                          @closeEditPipelineValueDialog="closeEditPipelineValueDialog"
    />

    <edit-stage-dialog :stage="stage_to_edit" :editStageDialogVisible="editStageDialogVisible"
                       @closeEditStageDialog="editStageDialogVisible=false"
                       @updatePipelinesArray="updatePipelinesArray"/>
    <remove-modal
      :loader="loaders.removeModal"
      :modal-text="modals.Removal.text"
      :identity-data="modals.Removal.identityData.type"
      :removeId="modals.Removal.id"
      :button-text="{success:'OK',defer:'Cancel'}"
      @onRemove="handleDelete"/>
  </div>
</template>
<script>

  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import AddPipelineDialog from "@/views/Components/Pipeline/AddPipelineDialog";
  import EditPipelineDialog from "@/views/Components/Pipeline/EditPipelineDialog";
  import EditPipelineValue from "@/views/Components/Pipeline/EditPipelineValue";
  import EditStageDialog from "@/views/Components/Pipeline/EditStageDialog";
  import AddStageDialog from "@/views/Components/Pipeline/AddStageDialog";
  import RemoveModal from "../../../components/Modals/RemoveModal";
  import Draggable from 'vuedraggable'
  import Helper from "@/util/globalHelpers"
  import authHelper from "@/util/authHelper";
  import PipelineAutomationRulesListing from "../../Components/Pipeline/PipelineAutomationRulesListing";
  import BackButton from "@/components/Router/BackButton";

  export default {
    components: {
      PipelineAutomationRulesListing,
      AddPipelineDialog: AddPipelineDialog,
      EditPipelineDialog: EditPipelineDialog,
      EditPipelineValue: EditPipelineValue,
      EditStageDialog: EditStageDialog,
      AddStageDialog: AddStageDialog,
      Draggable,RemoveModal,
      BackButton
    },
    data() {
      return {
        pipelines: [],
        addPipelineAutomationRuleDialogVisible: false,
        addPipelineDialogVisible: false,
        editPipelineDialogVisible: false,
        dialogVisibility: false,
        editStageDialogVisible: false,
        addStageDialogVisible: false,
        pipeline_to_edit: {},
        stage_to_edit: {},
        api_param: {
          moved_stage_id: '',
          pipeline_id: '',
          old_index: '',
          new_index: '',
          new_order_list: [],
        },
        loaders:{
          removeModal: false,
          stageLoader: false
        },
        modals:{
          Removal:{
            text: 'If you delete this stage, any patients in this stage will be moved to unassigned.',
            identityData: {
              type: 'stage'
            },
            id: ''
          },
        },
        deletePipelineAutomationRuleData:{
          pipelineId:null
        },
        delete_stage_param : {
          stage_id : ''
        },
        delete_pipeline_param : {
          pipeline_id : ''
        },
        deleteIndices: {
          stageIndex: 0,
          pipelineIndex: 0
        },
        default_pipelines:[
          'Pre-Op',
          'Post-Op',
          'Lost-Patients',
          'Non-Surgical',
        ],
        pipelineValues: [],
        activeTab: '0'
      };
    },
    watch: {
      'activeTab': function(newVal) {
        authHelper.setActiveTabInCustomizePipeline(newVal);
      }
    },
    created() {
      this.updatePipelinesArray();
    },
    mounted() {
      this.activeTab = authHelper.getActiveTabInCustomizePipeline()
    },
    methods: {
      addCommaToAmount(valueString) {
        return Helper.addCommaToAmount(valueString.toString())
      },
      handleDelete(id, type) {
        if (type === 'stage') {
          let vm = this;
          this.deleteStageRequest(id);
          this.$store.commit('pipelineModule/updateRefreshPipelineAutomationRuleListing', true);
          setTimeout(function (){
            vm.$store.commit('pipelineModule/updateRefreshPipelineAutomationRuleListing', false);
          },500)

        }else if(type === 'automation_rule'){
          this.deletePipelineAutomationRuleRequest(id)
        }else this.deletePipelineRequest(id)
      },
      deleteStage(stage, stage_index, pipeline_index) {
        this.deleteIndices.stageIndex = stage_index
        this.deleteIndices.pipelineIndex = pipeline_index
        this.modals.Removal.text = 'If you delete this stage, any patients in this stage will be moved to unassigned.'
        this.modals.Removal.identityData.type = 'stage'
        this.modals.Removal.id = stage.id
        this.$store.commit("showRemoveModal")
      },
      deletePipelineAutomationRule(data) {
        this.modals.Removal.text = 'Are you sure you want to delete this automation?'
        this.modals.Removal.identityData.type = 'automation_rule'
        this.modals.Removal.id = data.id
        this.$store.commit("showRemoveModal")
        this.deletePipelineAutomationRuleData.pipelineId=data.pipeline_id;
      },
      deletePipelineAutomationRuleRequest(id) {
        let params = {
          id: id
        }
        let vm = this;
        vm.$store.dispatch('pipelineModule/_deleteAutomationRule', params).then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Pipeline Automation Rule deleted successfully.'
          });
          let ruleListingRef = `pipelineAutomationRuleListing${this.deletePipelineAutomationRuleData.pipelineId}`
          vm.$refs[ruleListingRef][0].removeRuleFromList(id);
        }).catch(error => {
        }).finally(() => {
          this.$store.commit("hideRemoveModal")
        })
      },
      deletePipeline(pipeline, pipeline_index) {
        if (this.isDefaultPipeline(pipeline)) {
          swal.fire({
            text: 'You cannot delete this pipeline. This pipeline has your unassigned patients. We recommend editing this pipeline instead.',
          })
        } else {
          this.deleteIndices.pipelineIndex = pipeline_index
          this.modals.Removal.text = 'Are you sure you want to delete this pipeline?' +
            ' Any patients in this pipeline will be moved to unassigned stage of Pre-op.'
          this.modals.Removal.identityData.type = 'pipeline'
          this.modals.Removal.id = pipeline.id
          this.$store.commit("showRemoveModal")
        }
      },
      deleteStageRequest(id) {
        this.delete_stage_param.stage_id = id;
        const config = {
          params: this.delete_stage_param,
        };
        axios.delete(this.$store.getters.getBaseUrl + '/api/delete-stage', config)
          .then((response) => {
            this.$store.commit('toggleSessionCheck', true)
            this.$store.commit('updateSessionTime', 0)
            this.$delete(this.pipelines[this.deleteIndices.pipelineIndex].stages, this.deleteIndices.stageIndex);
            this.$notify.success({
              title: 'Success',
              message: 'Deleted successfully'
            });
            this.$store.commit("hideRemoveModal")
          }).catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          this.$notify.error({
            title: 'Error',
            message: message
          });
        }).finally(() => this.loader = false);
      },
      deletePipelineRequest(id) {
        this.delete_pipeline_param.pipeline_id = id;
        const config = {
          params: this.delete_pipeline_param
        };
        axios.delete(this.$store.getters.getBaseUrl + '/api/delete-pipeline',
          config
        ).then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$delete(this.pipelines, this.deleteIndices.pipelineIndex);
          this.activeTab = `${this.pipelines.length - 1}`;
          this.$store.commit("hideRemoveModal")
          this.$notify.success({
            title: 'Success',
            message: 'Deleted successfully'
          });
        }).catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          this.$notify.error({
            title: 'Error',
            message: message
          });
        }).finally(() => this.loader = false);
      },
      isUnassignedExists(pipeline){
        let found = false;
        pipeline.stages.forEach((stage, index) => {
          if (stage.title == 'Unassigned'){
            found = true;
          }
        });
        return found;
      },
      isDefaultPipeline(pipeline) {
          return this.default_pipelines.includes(pipeline.title);
      },
      openAddPipelineDialog() {
        this.addPipelineDialogVisible = true;
      },
      closeAddPipelineDialog() {
        this.addPipelineDialogVisible = false;
      },
      openEditPipelineDialog(pipeline) {
        this.pipeline_to_edit = pipeline;
        this.editPipelineDialogVisible = true;
      },
      openEditPipelineValue(pipeline) {
        this.pipeline_to_edit = pipeline;
        this.dialogVisibility = true;
      },
      closeEditPipelineValueDialog(newValue,pipelineId){
        // console.log(newValue,pipelineId)
        for (let i = 0; i < this.pipelines.length; i++) {
          if (this.pipelines[i].id === pipelineId){
            this.pipelineValues[i] = newValue
            break
          }
        }
        this.dialogVisibility=false
      },
      isLostPatient(title){
        let testString = title.toLowerCase()
        testString = testString.replace(/[^A-Za-z\s!?]/g, '');
        return (testString === 'lostpatient' || testString === 'lostpatients')
      },
      openEditStageDialog(stage) {
        this.stage_to_edit = stage;
        this.editStageDialogVisible = true;
      },
      openAddStageDialog(pipeline) {
        this.pipeline_to_edit = pipeline;
        this.addStageDialogVisible = true;
      },
      updatePipelinesArray() {
        this.$store.dispatch('getPipelinesWithStagesAction').then(response => {
          this.pipelines = this.$store.getters.getPipelines;
          this.pipelineValues = []
          for (const pipeline of this.pipelines) {
            this.pipelineValues.push(pipeline.value)
          }
        }).catch((error) => {})
      },
      stageOrderChanged(event) {
        this.loaders.stageLoader = true;

        this.api_param.old_index = event.moved.oldIndex;
        this.api_param.new_index = event.moved.newIndex;
        this.api_param.moved_stage_id = event.moved.element.id;
        this.api_param.pipeline_id = event.moved.element.pipeline_id;
        this.api_param.new_order_list = [];

        let pipelineIndex = this.pipelines.findIndex(pipeline => pipeline.id == event.moved.element.pipeline_id);
        if (pipelineIndex > -1) {
          this.pipelines[pipelineIndex].stages.forEach(item => {
            this.api_param.new_order_list.push(item.id);
          })
        }
        this.loaders.stageLoader = false;
        this.updateStageOrder();
      },

      updateStageOrder() {
        axios.put(this.$store.getters.getBaseUrl + '/api/update-stage-order',
          this.api_param,
        ).then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          // this.$store.commit('setPipelineStages', {pipeline_id: this.api_param.pipeline_id, stages : response.data.data});
          // this.pipelines = this.$store.getters.getPipelines;
        }).catch(error => {
          console.error(error)
        }).finally(()=>{
        });
      },
      getPipelineTitleForHeading(pipeline) {
      if (this.isDefaultPipeline(pipeline) && pipeline.editable_title != pipeline.title) {
        return `${pipeline.editable_title} <span style='font-size: 12px; font-weight: bold; opacity: 0.7;'>(default for: ${pipeline.title})</span>`
      }
      return pipeline.title;
    }
    }
  };
</script>

<style lang="scss" scoped>

  .list {
    display: flex;
    flex-wrap: wrap;
  }

  .list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .list-content h4 {
    flex: 1 0 auto;
    word-wrap: break-word;
  }

  .add_pipeline_btn {
    background-color: #94B527 !important;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 6px;
  }

  .add_pipeline_btn:focus {
    color: white;
  }

  .tab_main {
    border: none;
    border-radius: 4px;
  }

  .width_25 {
    width: 25%;
  }

  .card-body {
    padding: 1rem !important;
  }

  .success_btn:hover {
    color: white !important;
    background-color: #2F597A !important;
  }

  .pointer {
    cursor: pointer;
  }

  .stage_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
  }

  .line_height_17 {
    line-height: 20px;
  }

  .tab_main {
    box-shadow: none !important;
  }


</style>

<style>
  .customize-pipeline-container .el-tabs__header {
    background-color: rgb(242, 245, 252) !important;
  }

  .customize-pipeline-container .el-tabs__item {
    border: none !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .customize-pipeline-container .el-tabs__item.is-active {
    color: #909399 !important;
  }

  .customize-pipeline-container .el-tabs__item:hover {
    color: #909399 !important;
  }

  .pipeline-delete-warning-font {
    color: rgba(220, 38, 38, 100);
    font-weight: bold;
    font-size: 0.75rem;
  }
</style>
