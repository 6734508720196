<template>
  <div style="background: #F2F5FC; height: calc(100vh - 77px)">

    <div style="height: 100%" class="container-fluid m">
      <div class=" m-0  pt-4 pb-3 pr-2 pipeline_header">
        <h1 class=" d-inline-block mr-3  mb-0" style="">Patient Pipeline</h1>
        <p id="patient-count-box">Total Patients: {{ pipeline_data.total_patients_count }}</p>

        <div class="d-inline-block ml-auto">
          <base-input class="d-inline-block search_input input_height_30 pipeline white-border" type="search"
                      v-model="searchText"
                      placeholder="Search..." @keyup="searchPipelinePatients" @search="searchFunc"
          >
          </base-input>
          <div id="search_result_box" v-if="searchText.trim().length>0&&showDropdown" class="search_result_box"
               v-loading="loader.search">
            <div v-if="paginate.totalRecords>0">
              <p class="results-info">
                <span class="search-total-results">{{ paginate.totalRecords }} results</span>
              </p>
              <div v-for="searchItem in searchResults" class="single-search-item border-bottom"
                   style="margin-bottom: 11px">
                <span @click="showPatientsPipeline(searchItem)">
                  <p class="personal_info d-flex align-items-center ">
                    <span class="search-bt-logo"><img alt="" src="/img/svg/BTLogoOrng.svg"></span>
                    <span class="search_name">{{ searchItem.patient.first_name + ' ' + searchItem.patient.last_name }}</span>
                    <span class="search_dob">{{
                        searchItem.patient ? searchItem.patient.dob ? ` . ${searchDOBFormat(searchItem.patient.dob)}` : '. NA' : ''
                      }}</span>
                  </p>
                  <p class="search-patient-MRN m-0" style="padding-bottom : 11px;">
                  <span>MRN:{{
                      searchItem.patient ? searchItem.patient.mrn_number ? `${searchItem.patient.mrn_number}` : 'NA' : ''
                    }}</span>
                  <span>{{
                      searchItem.pipeline ? searchItem.pipeline.editable_title ? ` . ${searchItem.pipeline.editable_title}` : '- NA' : ''
                    }}</span>
                  </p>
                </span>
              </div>
              <p v-if="paginate.totalRecords>3&&searchResults.length>0" @click="loadMore" id="load_more"
                 class="load-more"
                 style="display: block; text-align:center; cursor: pointer; margin-bottom: 0;font-weight: bolder;font-size: 13px;">
                {{ btnLoadMoreText }}</p>
            </div>
            <div v-if="notFound" class="noRecordFound">
              <span class="spn-search-not-found">
                <span class="spn-search-not-found-img d-flex justify-content-center align-items-center">
                  <img class="" src="/img/svg/search-focus.svg" alt="">
                </span>
                <span class=" d-flex justify-content-center align-items-center">
                  <p class="spn-search-not-found-txt">No Results Found</p>
                </span>
              </span>
            </div>
          </div>

        </div>
        <div class="d-flex justify-content-center" >
          <base-button type="secondary" style="min-width:129px" class="ml-3 white-outline-button button-font input_height_30 btn-uscf-pipeline"
                       @click="$store.commit('showUCFS')">
            <div class="d-flex align-items-center ">
              <div class="mr-5p">
                <img class="mr-2" src="/img/svg/filters.svg" alt="Filters">
                <span>Filter</span>
              </div>
              <el-badge :value="getFiltersCount" class="pipeline filter-count-badge" type="warning"></el-badge>
            </div>
          </base-button>

          <base-input class="d-inline-block ml-2  pipelines_dropdown input_height_30">
            <el-select
              class="select-primary pagination-select pipeline white-border"
              placeholder="Pipelines"
              v-model="get_api_param.pipeline_id"
              value-key="1"
              @change="createCategoriesArrAndApiCall"
            >
              <el-option
                class="select-primary"
                v-for="(pipeline , index) in pipelines"
                :key="index"
                :label="pipeline.editable_title"
                :value="pipeline.id"
              >
              </el-option>
            </el-select>
          </base-input>

          <div class="ml-2 d-flex justify-content-center">
            <div @click="showColorsModal" class="justify-content-center align-items-center cog-container pointer"
                 style="background-color: white;"
            >
              <img class="px-2" src="/img/key_icon.svg" alt="">
            </div>
          </div>
          <div class="ml-2 d-flex justify-content-center">
            <el-tooltip
              effect="dark"
              :content="'Download Report'"
              placement="bottom-start">
              <base-button type="secondary"
                           class="px-1 white-outline-button cog-container pointer btn-uscf-pipeline"
                           @click="downloadPipelinePatientReport"
                           :disabled="downloadingReport">
                <img alt="Image placeholder" src="/img/svg/export.svg">
              </base-button>
            </el-tooltip>
          </div>
          <div v-if="showCustomizeSettingButton" class="ml-2 d-flex justify-content-center" @mouseenter="handleCogHover()"
               @mouseleave="handleCogLeave()">
            <router-link class="justify-content-center align-items-center cog-container"
                         style="background-color: white;" :to="{name: 'CustomizePipeline'}"
                         :class="{'d-none':cogHovered}"
            >
              <img class="px-2" src="/img/setting_white.svg" alt="">
            </router-link>
            <router-link class="justify-content-center align-items-center cog-container"
                         style="background-color: #2F597A;" :to="{name: 'CustomizePipeline'}"
                         :class="{'d-none':!cogHovered}"
            >
              <img class="px-2" src="/img/setting_green.svg" alt="">
            </router-link>
          </div>

          <router-link v-else class="ml-2 justify-content-center align-items-center cog-container"
                       :is="'span'"
                       style="background-color: white; cursor: not-allowed;" :to="{name: 'CustomizePipeline'}"
                       :class="{'d-none':cogHovered}"
          >
            <el-tooltip
              effect="dark"
              :content="'Contact your Baritasitc Rep if you’d like access to this feature.'"
              placement="bottom-start">
            <img class="px-2" src="/img/setting_white.svg" alt="">
            </el-tooltip>
          </router-link>
        </div>
      </div>
      <div ref="pipelinesRef" style="height: calc(100% - 180px)" :class="showScrollbar" v-loading="loader.pipelines" class="d-flex">
        <div v-if="loading"></div>
        <div v-for="(stage, stage_index) in pipeline_data.stages"
             :key="stage.id"
             style="display: inline-block;height: 100%; "
             v-bind:style="{ width: pipeline_width+ 'px' }"
             class="no-border-card p-1 " body-classes="px-0 pb-1" footer-classes="pb-2">
          <div
            style="height: 100%; padding: 10px; background-color: #F9F9F9; border-radius: 4px; box-shadow: 0 3px 2px #E9ECEF0D;"
            class="w-full max-w-md text-center"
            v-loading="wholeStageLoading(stage.id)"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <el-tooltip
                  class="item" effect="dark"
                  :content="stage.title"
                  popper-class="stage-text-tooltip"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <p
                    class="mb-0 align-items-start text-gray-700 font-semibold font-sans tracking-wide pipeline_stage_heading">
                    {{ stage.title }}</p>
                </el-tooltip>
                <p class="text-left pb-2 mb-0" style="font-size: 13px">
                  ${{ calculateValue(stage.pipeline_id, stage.patient_count) + ' - ' + stage.patient_count }}
                  Patients</p>
              </div>
              <div @click="getPipelinePatientsOrderByChecklistPercentageCompletion(stage.id)">
                <el-tooltip
                  class="item" effect="dark"
                  :content="getTooltipForPercentageSortIcon(stage.id)"
                  popper-class="stage-text-tooltip"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                <img :id="`sortByChecklistPercentageCompletion${stage.id}`" :style="`opacity: ${getSortByChecklistPercentageCompletionClicked(stage.id)? 1:0.5}`" style="cursor: pointer"
                     src="/img/svg/arrow_down_black.svg" height="15" width="15" alt="image">
                </el-tooltip>
              </div>
            </div>

            <draggable @end="log" :id="stage.id" :data-stage-index="stage_index" group="all-users"
                       ghost-class="moving-card"
                       filter=".action-button"
                       v-scroller="handleScroll"
                       class="w-full max-w-md stage-scroller" :list="stage.pipeline_patients"
                       :animation="200"
                       style="height: 88%; overflow: auto;">

              <template v-for="patient in stage.pipeline_patients">
                <pipeline-patient-card v-if="patient != null"
                                       :user="patient"
                                       :key="patient.id"
                                       :stage="stage"
                                       :id="'card_'+patient.id"
                                       :ref="'card_'+patient.id"
                />
              </template>

              <div class="pipeline-loader-container" v-if="!showLoader(stage.id)">
                <div class="pipeline-loader" v-loading="getLoader(stage.id)"></div>
              </div>
            </draggable>
            <div :class="(!scrolls[stage_index].loaded && !scrolls[stage_index].totalCountFetched  && scrolls[stage_index].page>=2 && !getLoader(stage.id))? 'visible' : 'invisible'" class="load-all" >
              <button
                type="button" class="text-decoration-none pointer"
                @click="loadRemainingStagePatients(stage.id)"
              >Load All
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style="height: 95px" class="pipeline-button-container bg-white p-2 row">

        <template v-if="get_api_param.pipeline_id!=lostPipeline.lostPipelineId">

          <div class="change-stage-buttons ml-2">
            <draggable id="lost" group="all-users"
                       ghost-class="lost-ghost" filter=".action-button"
                       class="w-full max-w-md lost_patient_area" :list="lostPipeline.lost_patients"
                       :animation="200">
              <p class="lost_p">Lost</p>
            </draggable>
          </div>

        </template>

        <div class="change-stage-buttons ml-2">
          <draggable id="change" group="all-users"
                     ghost-class="change-ghost" filter=".action-button"
                     class="w-full max-w-md change_patient_area float-left" :list="change_patients" :animation="200">
            <p class="change_p"> Change</p>
          </draggable>
        </div>
      </div>
    </div>

    <change-pipeline-dialog :changeDialogVisible="changeDialogVisible" :pipelines="pipelines"
                            :pipeline_patient="change_patients[0]"
                            :selected-pipeline-title="selectedPipelineTitle"
                            @closeDialog="changeDialogVisible=false" @movePatient="changeStage"
                            @removeFromDialog="removeFromDialog"
                            />

    <mark-as-lost-modal :pipelines="pipelines" :pipeline_patient="lostPipeline.lostPatient" @onCancelMarkAsLost="removeFromMarkAsLost" @onMarkAsLost="markAslost"/>

    <div class="tempClassUCFS">
      <user-category-sidebar
        :showChecklistTemplate="true"
        :show-app-activity="true"
        :show-current-stage-filter="true"
        :show-surgery-date-filter="true"
        ref="UserCategorySidebarFilter"/>
    </div>
    <modal :show.sync="showModal" >

      <h2 slot="header" class="mt-3" id="modal-title-default">Stage Colors  </h2>

      <div class="mt--4">
        <div class="d-inline-block">
          <div style="border-top: solid 7px #B2D4FE; width: 36px; border-radius: 7px"></div>
          <p class="mt-1 colors-modal-text">Pre-Op</p>
        </div>
        <div class="d-inline-block ml-4">
          <div style="border-top: solid 7px #CEE5D0; width: 36px; border-radius: 7px"></div>
          <p class="mt-1 colors-modal-text">Post-Op</p>
        </div>
        <div class="d-inline-block ml-4">
          <div style="border-top: solid 7px #FEB7B7; width: 36px; border-radius: 7px"></div>
          <p class="mt-1 colors-modal-text">Non - Surgical</p>
        </div>
        <div class="d-inline-block ml-4">
          <div style="border-top: solid 7px #FFDEAC; width: 36px; border-radius: 7px"></div>
          <p class="mt-1 colors-modal-text">Considering It</p>
        </div>

        <div>
          <h2 class="mt-1 mb-3" >Icons  </h2>
          <P class="mb-0 colors-modal-text"><span class="mr-3"><img src="/img/svg/phone-wob.svg" height="28px" width="17px" alt=""></span>Patient has never used the app.</P>
          <P class="mb-0 mt-3 colors-modal-text"><span class="mr-3"><img src="/img/svg/phone-green-wob.svg" height="28px" width="17px" alt=""></span>Patient has used the app.</P>
          <P class="mb-0 mt-3 colors-modal-text"><span class="mr-3"><img src="/img/svg/alert-gray.svg" height="28px" width="17px" alt=""></span>No active alerts for the patient.</P>
          <P class="mb-0 mt-3 colors-modal-text"><span class="mr-3"><img src="/img/svg/alert.svg" height="28px" width="17px" alt=""></span>One or more active alerts for this patient.</P>
          <P class="mb-0 mt-3 colors-modal-text"><span class="mr-3"><img src="/img/svg/shuffle.svg" height="28px" width="17px" alt=""></span>Patient has moved via an automation.</P>
        </div>
      </div>

    </modal>

  </div>
</template>
<script>

import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import Draggable from 'vuedraggable'
import PipelinePatientCard from "@/views/Components/Pipeline/PipelinePatientCard";
import ChangePipelineDialog from "@/views/Components/Pipeline/ChangePipelineDialog";
import MarkAsLostModal from "@/views/Components/Pipeline/MarkAsLostModal";
import moment from "moment/moment";
import UserCategorySidebar from "../../Components/UserCategorySidebar";
import Badge from "../../../components/Badge";
import Helper from "@/util/globalHelpers"
import fileDownload from "@/mixins/fileDownload";
import {mapState} from "vuex";


export default {
  name:'Pipeline',
  components: {
    // other components
    Draggable,
    PipelinePatientCard,
    ChangePipelineDialog: ChangePipelineDialog,
    MarkAsLostModal, UserCategorySidebar, Badge
  },

  data() {
    return {
      showModal: false,
      loader: {
        pipelines: false,
        search: false,
      },
      scrolls: [],
      activeIndex: '1',
      pipelines: [],
      pipeline_data: {},
      pipeline_data_dup: {},
      pipeline_width: 225,
      change_patients: [],
      current_pipeline: '',
      lostPipeline: {
        lostPipelineId: '',
        lost_patients: [],
        lostPatient: {},
        patient_index: ''
      },
      get_api_param: {
        patientId: null,
        search: null,
        pipeline_id: null,
        surgeryDate: '',
        surgeryDateDuration: ''
      },
      change_stage_api_param: {
        pipeline_patient_id: null,
        new_stage_id: null,
        old_stage_id: null,
        pipeline_id: null,
        lostReason: '',
        lostComment: '',
        resetDays: false,
      },
      changeDialogVisible: false,
      loading: true,
      stage_index_for_changing_item: null,
      patient_index_for_changing_item: null,
      searchText: '',
      searchResults: [],
      isFinished: false,
      notFound: false,
      paginate: {
        perPage: 3,
        totalRecords: 0,
        currentPage: 1,
        lastPage: 1,
      },
      row: 0,
      btnLoadMoreText: '',
      showDropdown: true,
      response: {
        stages: [],
        categories: [],
        defaultCategories: [],
        checklistTemplates: [],
      },
      filtersCount: 0,
      cogHovered: false,
      pipeline_value_by_id: {},
      mobileOption: 0,
      currentStageFilter: 0,
    };
  },
  mixins: [
    fileDownload
  ],
  computed: {

    showCustomizeSettingButton() {
      if (this.$store.getters.getAuthUser && this.$store.getters.getAuthUser.type === "program_user" && !this.$store.getters.getAuthUser.administrative_rights) {
        return false;
      }
      return true;
    },

    selectedPipelineTitle() {
      if (this.pipelines.length && this.get_api_param.pipeline_id) {
        const pipeline = this.pipelines.filter(pipeline => pipeline.id == this.get_api_param.pipeline_id)
        return pipeline[0].title
      }
    },

    showScrollbar() {
      this.$refs.pipelinesRef ? this.$refs.pipelinesRef.scrollLeft = 0 : null;
      return this.loader.pipelines == false ? 'horizontal_scroll' : 'overflow-hidden'
    },
    getLostPipeline() {
      return this.pipelines.find(pipeline => pipeline.title === 'Lost-Patients');
    },
    getFiltersCount() {
      return this.filtersCount ?? 0;
    },
    // Mapping vuex states into the component using mapState Helper
    ...mapState({
      downloadingReport: state => state.modals.downloadingReport
    })
  },

  watch: {
    change_patients: {
      handler: function (obj) {
        if (this.change_patients.length > 0) {
          this.changeDialogVisible = true;
        }
      },
      deep: true
    }
  },

  created() {
    this.getFiltersData();
    this.fetchPipelines();
    let vm = this;
    this.$root.$on('UserSubCategoryFilterCount', (data) => {
      vm.filtersCount = data;
    });
    this.$root.$on('applyUserSubCategoryFilters', (data) => {
      vm.filtersCount = data.filtersCount;
      vm.mobileOption = data.mobileOption;
      vm.currentStageFilter = data.currentStageFilter;
      vm.get_api_param.surgeryDate = data.surgeryDate
      vm.get_api_param.surgeryDateDuration = data.surgeryDateDuration
      vm.getPipelinePatients(false, data.obj, data.checklistTemplateFilter);
    });
  },

  beforeDestroy() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },

  methods: {
    downloadPipelinePatientReport() {
      let vm = this
      vm.$store.commit('toggleDownloadReport', true)
      const config = {
        responseType: "blob"
      }
      vm.$store.dispatch('_pipelinePatientsExcel', config)
      .then(response => {
          if (vm.downloadingReport) {
            vm.generateExportFileDownload(response, 'Baritastic-Pipeline-Patients')
          }
      })
      .catch(error => {
        const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Pipeline Patients Report',
          message: message
        })
      })
      .finally(() => {
        vm.$store.commit('toggleDownloadReport', false)
      })
    },

    showColorsModal() {
      this.showModal = true;
    },

    calculateValue(pipelineId, count) {
      return Helper.addCommaToAmount((count * this.pipeline_value_by_id[pipelineId]).toString())
    },

    handleCogHover() {
      this.cogHovered = true
    },

    handleCogLeave() {
      this.cogHovered = false
    },

    getFiltersData() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/pre-load-filters?page=' + 'pipeline-patients'
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.stages = response.data.data.stages;
        vm.response.categories = response.data.data.categories;
        vm.response.defaultCategories = response.data.data.defaultCategories;
        vm.response.checklistTemplates = response.data.data.checklist_templates;
        vm.get_api_param.pipeline_id = response.data.data.default_pipeline_id;
        this.$store.commit('setUserSubcategories', {
          categories: vm.response.categories,
          defaultCategories: vm.response.defaultCategories
        })
        this.$store.commit('setChecklistTemplateFilters', {
          data: vm.response.checklistTemplates,
        })
        let obj = [];
        vm.response.categories.forEach((category, index) => {
          var i = vm.response.defaultCategories.findIndex(dc => {
            if (dc.user_category_id == category.id) {
              return true;
            }
          });
          if (i != -1) {
            obj.push(vm.response.defaultCategories[i].user_sub_category_id);
          }
        });
        vm.getPipelinePatients(false, obj);
        this.loading = false;
      }).catch(error => {
        console.log(error.response)
      });
    },

    categoriesData() {
      let userSubCategoriesIds = [];
      this.response.categories.forEach(category => {
        if (category.state && !isNaN(category.state)) {
          userSubCategoriesIds.push(category.state);
        }
      });
      return userSubCategoriesIds;
    },

    createCategoriesArrAndApiCall() {
      this.getPipelinePatients(false, this.categoriesData(),this.$refs.UserCategorySidebarFilter.getChecklistTemplateFilters());
    },

    searchDOBFormat(dob) {
      return moment(dob).format('MMM DD,yyyy')
    },

    getLoader(id) {
      let elem = this.scrolls.find(scroll => scroll.id == id);
      return elem.loading;
    },

    getSortByChecklistPercentageCompletionClicked(id) {
      let elem = this.scrolls.find(scroll => scroll.id == id);
      return elem.sortByChecklistPercentageCompletion;
    },

    getTooltipForPercentageSortIcon(id) {
      return !this.getSortByChecklistPercentageCompletionClicked(id) ? 'Sort By % Complete' : 'Disable Sort By % Complete';
    },

    showLoader(id) {
      let elem = this.scrolls.find(scroll => scroll.id == id);
      return elem.loaded;
    },

    wholeStageLoading(id){
      let elem = this.scrolls.find(scroll => scroll.id == id);
      return elem.stageLoader;
    },

    handleScroll(evt, el) {
      if (!this.loading) {
        let offset = Math.ceil(el.scrollTop) - el.scrollTop;
        if ((el.scrollTop + offset) >= el.scrollHeight - el.offsetHeight) {
          let elem = this.scrolls.find(scroll => scroll.id == el.id);
          elem.page += 1;
          this.getStagePatients(elem);
        }
      }
    },

    setScrolls(stages) {
      this.scrolls = [];
      for (let stage of stages) {
        let obj = {
          id: stage.id,
          page: 1,
          title: stage.title,
          loading: false,
          loaded: false,
          stageLoader:false,
          totalCountFetched:false,
          count: stage.pipeline_patients.length,
          sortByChecklistPercentageCompletion: false,
        }
        stage.pipeline_patients.length < 20 ? obj.loaded = true : obj.loaded = false;
        this.scrolls.push(obj);
      }
    },

    json2array(json) {
      var result = [];
      var keys = Object.keys(json);
      keys.forEach(function (key) {
        result.push(json[key]);
      });
      return result;
    },

    getPipelinePatients(notify = false, userSubCategories = null, checklistTemplateFilters = null) {
      let vm = this;
      vm.loader.pipelines = true;
      vm.loading = true;
      const config = {
        params: {
          ...vm.get_api_param,
          user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null,
          checklist_templates: checklistTemplateFilters  && checklistTemplateFilters.length > 0 ? checklistTemplateFilters : null,
          mobile_option: vm.mobileOption,
          current_stage: vm.currentStageFilter
        }
      };
      axios.get(vm.$store.getters.getBaseUrl + '/api/pipeline-patients',
        config
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        response.data.data.stages.forEach((stage, index) => {
          stage.pipeline_patients = vm.json2array(stage.pipeline_patients);
        });
        vm.pipeline_data = response.data.data;
        vm.pipeline_data_dup = JSON.parse(JSON.stringify(response.data.data));
        vm.setScrolls(response.data.data.stages);
        vm.loading = false;
        if (notify == true) {
          vm.$notify.success({
            title: 'Success',
            message: 'updated successfully'
          });
        }
      }).catch(error => {
        var message = error.response ? error.response.data.message : 'Sorry. We encountered an error while fetching the data. Please refresh your screen.';
        vm.loading = false;
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        vm.loader.pipelines = false;
      });
    },

    getStagePatients(elem) {
      let vm = this;
      let prevCount = elem.count;

      let userSubCategories = this.categoriesData()
      let checklistTemplates = this.$refs.UserCategorySidebarFilter.getChecklistTemplateFilters()
      const config = {
        params: {
          pipeline_id: this.get_api_param.pipeline_id,
          user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null,
          checklist_templates: checklistTemplates ? checklistTemplates : null,
          mobile_option: vm.mobileOption,
          current_stage: vm.currentStageFilter,
          stage_id:elem.id,
          page:elem.page,
          title:elem.title,
          orderBy : elem.sortByChecklistPercentageCompletion ? 'checklist_percentage_completion' : null
        }
      };

      if (elem.loaded == false && elem.loading==false) {
        this.loading = true;
        if(!elem.stageLoader) elem.loading = true;
        axios.get(`${this.$store.getters.getBaseUrl}/api/stage-patients`,
          config
        ).then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          let stage = vm.pipeline_data.stages.find(stage => stage.id == elem.id);
          response.data.data.map(function (patient, key) {
            if (!stage.pipeline_patients.find(pipeline_patient => pipeline_patient.id == patient.id)) {
              stage.pipeline_patients.push(patient);
            }
          });

          elem.count = stage.pipeline_patients.length;

          prevCount === elem.count ? elem.loaded = true : elem.loaded = false;
          let stageIndex = this.pipeline_data.stages.findIndex(stage=> stage.id === elem.id);
          if(stageIndex!=-1){
            elem.totalCountFetched = elem.count === this.pipeline_data.stages[stageIndex].patient_count;
          }
          vm.pipeline_data_dup = JSON.parse(JSON.stringify(vm.pipeline_data));
        }).catch(error => {
          var message = error.response ? error.response.data.message : 'Sorry. We encountered an error while fetching the data. Please refresh your screen.';
          this.$notify.error({
            title: 'Error',
            message: message
          });
        }).finally(() => {
          vm.loading = false;
          elem.loading = false;
          elem.stageLoader = false;
        });
      }
    },

    loadRemainingStagePatients(stageId){
        this.getAllStagePatients(stageId)
        // this.getAllStagePatientsRelations(stageId)
    },

    getAllStagePatients(stageId) {
      let vm = this;
      let elem = vm.scrolls.find(scroll => scroll.id == stageId);
      elem.page = 1;

      elem.stageLoader = true;
      elem.loading = true;

      let prevCount = elem.count;
      let userSubCategories = vm.categoriesData();
      let checklistTemplates = this.$refs.UserCategorySidebarFilter.getChecklistTemplateFilters();
      let orderBy = elem.sortByChecklistPercentageCompletion ? 'checklist_percentage_completion' : null;
      const config = {
        params: {
          pipeline_id: this.get_api_param.pipeline_id,
          user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null,
          checklist_templates: checklistTemplates ? checklistTemplates : null,
          mobile_option: vm.mobileOption,
          current_stage: vm.currentStageFilter,
          stage_id: elem.id,
          title: elem.title,
          patients_count_to_skip: prevCount,
          orderBy: orderBy
        }
      };

      axios.get(`${this.$store.getters.getBaseUrl}/api/all-stage-patients`,
        config
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)

        let stage = vm.pipeline_data.stages.find(stage => stage.id === elem.id);
        response.data.data.data.map(function (patient, key) {
          if (!stage.pipeline_patients.find(pipeline_patient => pipeline_patient.id === patient.id)) {
            stage.pipeline_patients.push(patient);
          }
        });
        elem.count = stage.pipeline_patients.length;
        elem.loaded = true;
        vm.pipeline_data_dup = JSON.parse(JSON.stringify(vm.pipeline_data));
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Sorry. We encountered an error while fetching the data. Please refresh your screen.';
        vm.$notify.error({ title: 'Error', message: message });
      }).finally(() => {
          elem.stageLoader = false;
          elem.loading = false;
      });
    },

    getAllStagePatientsRelations(stageId) {
      let vm = this;
      let elem = vm.scrolls.find(scroll => scroll.id == stageId);

      let prevCount = elem.count;
      let userSubCategories = vm.categoriesData();
      let checklistTemplates = this.$refs.UserCategorySidebarFilter.getChecklistTemplateFilters();
      let orderBy = elem.sortByChecklistPercentageCompletion ? 'checklist_percentage_completion' : null;
      const config = {
        params: {
          pipeline_id: this.get_api_param.pipeline_id,
          user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null,
          checklist_templates: checklistTemplates ? userSubCategories : null,
          mobile_option: vm.mobileOption,
          current_stage: vm.currentStageFilter,
          stage_id: elem.id,
          title: elem.title,
          patients_count_to_skip: prevCount,
          orderBy : orderBy
        }
      };

      axios.get(`${this.$store.getters.getBaseUrl}/api/all-stage-patients-relations`,
        config
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        /**
         * settimeout is called to delay processing of response and for confirmations  that parent data is loaded completed from API: all-stage-patients
         */
        setTimeout(function () {
          try {
            let stageIndex = vm.pipeline_data.stages.findIndex(stage => stage.id == stageId);
            response.data.data.data.forEach((patient, key) => {
              let patientIndex = vm.pipeline_data.stages[stageIndex].pipeline_patients.findIndex(pipeline_patient => pipeline_patient.id === patient.id);
              if (patientIndex !== -1) {
                vm.$set(vm.pipeline_data.stages[stageIndex].pipeline_patients[patientIndex], 'checklists', patient.checklists)
                vm.$set(vm.pipeline_data.stages[stageIndex].pipeline_patients[patientIndex], 'latest_pipeline_patient_alert', patient.latest_pipeline_patient_alert)
                vm.$set(vm.pipeline_data.stages[stageIndex].pipeline_patients[patientIndex], 'last_pipeline_automation_rule_check', patient.last_pipeline_automation_rule_check)
                vm.$set(vm.pipeline_data.stages[stageIndex].pipeline_patients[patientIndex], 'latest_pipeline_automation_rule_applied', patient.latest_pipeline_automation_rule_applied)
                vm.$set(vm.pipeline_data.stages[stageIndex].pipeline_patients[patientIndex], 'latest_pipeline_patient_nutrition_meal', patient.latest_pipeline_patient_nutrition_meal)
                vm.$set(vm.pipeline_data.stages[stageIndex].pipeline_patients[patientIndex], 'percentage', patient.percentage)
              }
            });
          } catch (e) {
            console.log(e)
          }
        });
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Sorry. We encountered an error while fetching the data. Please refresh your screen.';
        vm.$notify.error({ title: 'Error', message: message});
      });
    },

    getPipelinePatientsOrderByChecklistPercentageCompletion(stageId){
      let elem = this.scrolls.find(scroll => scroll.id == stageId);
      elem.sortByChecklistPercentageCompletion = !elem.sortByChecklistPercentageCompletion;
      elem.page = 1
      elem.loading = false;
      elem.loaded = false
      elem.stageLoader = true;
      elem.totalCountFetched = false
      elem.count = 0
      let stage = this.pipeline_data.stages.find(stage => stage.id == stageId);
      stage.pipeline_patients = [];
      this.getStagePatients(elem)
    },

    fetchPipelines() {
      let vm = this;
      this.$store.dispatch('getPipelinesWithStagesAction').then(response => {
        this.pipelines = this.$store.getters.getPipelines;
        vm.lostPipeline.lostPipelineId = this.getLostPipeline.id;
        this.get_api_param.pipeline_id = this.$store.getters.getDefaultPipeline;
        for (const pipeline of vm.pipelines) {
          vm.pipeline_value_by_id[pipeline.id] = pipeline.value
        }
      }).catch(error => {

      })
    },

    log(event) {
      if (event.to.id != '' && event.to.id != 'delete' && event.to.id != 'change' && event.to.id != 'lost' && event.to.id != event.item._underlying_vm_.pipeline_stage_id) { // checking if stage changed
        this.changeStage(event.to.id, event.item._underlying_vm_);
      }

      if (event.to.id == 'lost') {
        this.lostPipeline.lostPatient = event.item._underlying_vm_;
        this.lostPipeline.patient_index = event.oldIndex;
        this.$store.commit('showMarkAsLostModal')
      }

      if (event.to.id == 'change') {
        // event.from.attributes[2].value have the value for stage index please have a look at it if you add a new data attribute
        //because adding new attribute may change the index
        this.stage_index_for_changing_item = event.from.attributes[2].value;
        this.patient_index_for_changing_item = event.oldIndex;
      }
    },

    changeStage(new_stage_id, element, pipeline_id = null, current_stage_index = null, lostReason = null, lostComment = null, resetDays = false) {
      let vm = this;
      if (vm.$refs['card_' + element.id].length > 0) {
        vm.$refs['card_' + element.id][0].$el.style.opacity = 0.5;
      }
      vm.change_stage_api_param.pipeline_patient_id = element.id;
      vm.change_stage_api_param.new_stage_id = new_stage_id;
      vm.change_stage_api_param.old_stage_id = element.pipeline_stage_id;
      vm.change_stage_api_param.pipeline_id = pipeline_id == null ? element.pipeline_id : pipeline_id;
      vm.change_stage_api_param.lostReason = lostReason;
      vm.change_stage_api_param.lostComment = lostComment;
      vm.change_stage_api_param.resetDays = resetDays;
      axios.put(vm.$store.getters.getBaseUrl + '/api/change-stage',
        vm.change_stage_api_param,
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        if (vm.$refs['card_' + element.id].length > 0) {//
          vm.$refs['card_' + element.id][0].$el.style.opacity = 1;
        }
        vm.change_patients = [];
        if (pipeline_id == element.pipeline_id && current_stage_index != null) {//means stage changed vie change pipeline
          vm.pipeline_data.stages[current_stage_index].pipeline_patients.push(element);
        } else if (pipeline_id != element.pipeline_id && current_stage_index != null) {
          this.pipeline_data.total_patients_count--;
          let old_stage = vm.pipeline_data.stages.find(stage => stage.id == element.pipeline_stage_id);
          old_stage.patient_count--;
        }
        if ((current_stage_index == null) || pipeline_id == element.pipeline_id) {
          let old_stage = vm.pipeline_data.stages.find(stage => stage.id == element.pipeline_stage_id);
          let new_stage = vm.pipeline_data.stages.find(stage => stage.id == new_stage_id);

          old_stage.patient_count--;
          new_stage.patient_count++;
        }
        element['last_pipeline_automation_rule_check'] = 0;
        element.pipeline_id = vm.change_stage_api_param.pipeline_id;
        element.pipeline_stage_id = vm.change_stage_api_param.new_stage_id;
        element.stage_times_from_date = response.data.data.stage_times_from_date;
        vm.pipeline_data_dup = JSON.parse(JSON.stringify(vm.pipeline_data));
      }).catch(error => {
        vm.change_patients = [];
        vm.pipeline_data = JSON.parse(JSON.stringify(vm.pipeline_data_dup));
        var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      });
    },

    removeFromDialog(pipeline_patient){
      let index =  this.patient_index_for_changing_item;
      this.addPipelinePatientBack(pipeline_patient,index);
      this.change_patients = this.change_patients.filter((el) => el.id != pipeline_patient.id);
    },
    removeFromMarkAsLost(pipeline_patient){
      let index =  this.lostPipeline.patient_index;
      this.addPipelinePatientBack(pipeline_patient,index);
      this.lostPipeline.lost_patients = this.lostPipeline.lost_patients.filter((el) => el.id != pipeline_patient.id );
    },
    addPipelinePatientBack(pipeline_patient,index){
      let stage_index =  this.pipeline_data.stages.findIndex((el)=> el.id == pipeline_patient.pipeline_stage_id);
      this.pipeline_data.stages[stage_index].pipeline_patients.splice(index,0,pipeline_patient);
    },
    markAslost(request) {
      this.changeStage(
        request.changeStage.stage_id,
        request.changeStage.patient,
        request.changeStage.pipeline_id,
        request.changeStage.stage_index,
        request.lost.reason,
        request.lost.comments
      );
      this.$store.commit('hideMarkAsLostModal')
    },

    searchPipelinePatients() {
      console.log('keyup');
      this.showDropdown = true;
      // clear timeout variable
      clearTimeout(this.timeout);
      let vm = this;
      this.timeout = setTimeout(function () {
        // this block of code will be executed after 1 second
        // handle stuff, call search API etc.
        vm.paginate.perPage = 3;
        // vm.searchResults = [];
        vm.paginate.currentPage = 1;
        if (vm.searchText.trim().length > 0) {
          vm.loader.search = true;
          vm.fetchPipelinePatients().then(data => {
            vm.searchResults = data.data;
            vm.paginate.currentPage = data.current_page;
            vm.paginate.lastPage = data.last_page;
            vm.paginate.totalRecords = data.total;
            if (vm.paginate.totalRecords > 3) {
              vm.btnLoadMoreText = 'Load More';
            }
            if (vm.paginate.totalRecords > 0) {
              vm.notFound = false;
            } else {
              vm.notFound = true;
            }
            vm.loader.search = false;
          });
        } else {
          vm.get_api_param.patientId = null;
          vm.getPipelinePatients();
        }
      }, 1000);
    },

    fetchPipelinePatients() {
      let vm = this;
      return axios.get(`${vm.$store.getters.getBaseUrl}/api/search-pipeline-patients?search=${vm.searchText}&page=${vm.paginate.currentPage}`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          return response.data.data;
        })
        .catch(error => {
          var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          this.$notify.error({
            title: 'Error',
            message: message
          });
        });
    },

    loadMore() {
      let vm = this;
      if (this.paginate.currentPage == this.paginate.lastPage) {
        this.btnLoadMoreText = 'No More Results';
      } else {
        this.paginate.currentPage++;
        this.fetchPipelinePatients().then(data => {
          vm.searchResults = vm.searchResults.concat(data.data);
          vm.paginate.currentPage = data.current_page;
          vm.paginate.lastPage = data.last_page;
          vm.paginate.totalRecords = data.total;
        });
      }
    },

    showPatientsPipeline(element) {
      this.get_api_param.patientId = element.user_id;
      this.get_api_param.pipeline_id = element.pipeline.id;
      this.showDropdown = false;
      this.$store.commit('hideUCFS');
      this.getPipelinePatients(false, null);
    },

    searchFunc() {
      console.log('search')
      let vm = this;
      vm.get_api_param.patientId = null;
      vm.showDropdown = false;
      // vm.$store.commit('setUserSubcategories',{categories:null,defaultCategories:null });
      // vm.$store.commit('setUserSubcategories',{categories:vm.response.categories,defaultCategories:vm.response.defaultCategories });
      this.$refs.UserCategorySidebarFilter.setDefaultCategories();
      this.$refs.UserCategorySidebarFilter.applyFilters();
      let obj = [];
      vm.response.defaultCategories.forEach((category, index) => {
        obj.push(category.user_sub_category_id);
      });
      vm.getPipelinePatients(false, obj,this.$refs.UserCategorySidebarFilter.getChecklistTemplateFilters());
    },

  }
};
</script>

<style lang="scss" scoped>
.load-all {
  padding: 6px 0px;
  width: 190px;
  position: relative;
  button {
    font: normal normal 600 12px/17px Open Sans !important;
    width: 100%;
    background: #002B39;
    border: 1px solid rgb(0, 43, 57);
    border-radius: 4px;
    padding: 3px 0px;
    height: 30px;
    min-height: 23px;
    color: #FFFFFF;
    box-shadow: 0px 2px 1px #B7B7B733;
  }
}
.colors-modal-text{
  font: normal normal 600 13px/18px Open Sans;
  letter-spacing: 0px;
  color: #707070;
}

.cog-container {
  border-radius: 4px;
  display: flex;
}

#patient-count-box {
  background: #2F597A 0 0 no-repeat padding-box;
  box-shadow: 0 3px 2px #E9ECEF0D;
  display: inline-block;
  padding: 0.25rem 1rem;
  margin-bottom: 0;
  font: normal normal 600 12px/17px Open Sans;
  color: white;
  text-transform: capitalize;
  border-radius: 2px;
}

.moving-card {
  opacity: 0.5;
  color: rgb(93, 93, 156) !important;
  border: 3px dashed lightgrey;
  background-color: rgb(93, 93, 156);
  // @apply opacity-50 bg-gray-100 border border-blue-500;
}

.pipeline-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-stage-buttons {
  position: relative;
}

.delete-ghost {
  opacity: 0.8;
  position: absolute;
  width: 300px !important;
  height: 20px;
  left: 8px;
  top: 0;
  background-color: rgb(243, 192, 192) !important
}

.lost-ghost {
  opacity: 0.5;
  position: absolute;
  width: 300px !important;
  height: 20px;
  left: 8px;
  top: 0;
  background-color: #BB2255 !important;
}

.change-ghost {
  opacity: 0.5;
  position: absolute;
  width: 300px !important;
  height: 20px;
  left: 8px;
  top: 0;
  background-color: #2F597A !important
}

.delete_patient_area {
  border: dashed 2px #f5365c;
  height: 45px;
  border-radius: 5px;
  width: 315px;
  margin-top: 20px;
}

.lost_patient_area {
  border: dashed 2px #BB2255;
  height: 45px;
  border-radius: 5px;
  width: 315px;
  margin-top: 20px;
}

.change_patient_area {
  border: dashed 2px #172b4d;
  height: 45px;
  border-radius: 5px;
  width: 315px;
  margin-top: 20px;
}

.delete_p {
  width: 100%;
  padding-top: 7px;
  text-align: center;
  color: #f5365c;
  font-weight: bold;
  opacity: 0.7;
}

.lost_p {
  width: 100%;
  padding-top: 7px;
  text-align: center;
  color: #BB2255;
  font-weight: bold;
  opacity: 0.7;
}

.change_p {
  width: 100%;
  padding-top: 7px;
  text-align: center;
  color: #172b4d;
  font-weight: bold;
  opacity: 0.7;
}

.dots_on_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search_input {
  width: 344px;
  height: 30px;
}

.horizontal_scroll {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.pipeline_header {
  display: flex;
  align-items: center;
}

.form-group {
  margin-bottom: 0px;
}

.pipelines_dropdown {
  width: 135px;
  height: 30px;
}

.form-group {
  width: 117px !important;
  height: 30px !important;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #F56036;
  border-right: 5px solid #F56036;
  border-bottom: 5px solid #F56036;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  margin: auto;
  left: 50%;
  top: 40%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pipeline-loader-container {
  position: relative;
  height: 80px;
}

.pipeline-loader {
  position: absolute;
  top: 35%;
  left: 40%;
  transform: translate(-50%, -50%)
}

.stage-scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: rgb(250, 250, 250);
}

.stage-scroller::-webkit-scrollbar {
  border-radius: 10px;
  width: 7px;
  height: 7px;
  background-color: rgb(226, 226, 226);;
}

.stage-scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(226, 226, 226);
}

.horizontal_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: rgb(250, 250, 250);
}

.horizontal_scroll::-webkit-scrollbar {
  border-radius: 10px;
  width: 7px;
  height: 7px;
  background-color: rgb(226, 226, 226);;
}

.horizontal_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(226, 226, 226);
}

.btn-uscf-pipeline {
  height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}


</style>

<style>
.stage-text-tooltip {
  max-width: 400px;
  word-break: break-word;
}

/* Pipeline Style Over-rides */
.pipeline.white-border input, .pipeline.white-border input:hover {
  border: 1px solid white;
}

.pipeline.filter-count-badge {
  height: 1.25rem;
}

.no-border-card .card-footer {
  border-top: 0;
}

.input_height_30 input {
  /*border: none !important;*/
  height: 30px !important;
}

.input_height_30 .el-input__suffix {
  margin-top: 5px;
}

.is-reverse {
  margin-top: -8px;
}

.el-menu {
  background-color: transparent;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}

.el-submenu__title:hover {
  background-color: transparent;
}

.el-submenu__title:hover {
  background-color: transparent !important;
}

.el-submenu__title {
  height: 18px !important;
  line-height: 0px !important;
}

.search_result_box {
  max-height: 255px;
  overflow: auto;
  position: absolute;
  background-color: white;
  width: 344px;
  z-index: 100;
  display: block;
  border-radius: 4px;
  /*margin-top: 8px;*/
}

.search-total-results {
  text-align: left;
  font: normal normal normal 12px/14px Karla;
  letter-spacing: 0px;
  color: #8898AA;
  opacity: 1;
  margin-top: 5px;
  padding-left: 4px;
}

.results-info {
  margin-top: 3px;
  margin-bottom: 0px;
  line-height: 25px;
  padding-left: 8px;
}

.personal_info {
  margin-top: 3px;
  margin-bottom: 0px;
  line-height: 25px;
  padding-left: 8px;
}

.search_name {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
  padding-left: 12px;
}

.search_dob {
  text-align: left;
  font: normal normal normal 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
  padding-top: 1px;
}

.search-bt-logo {
  /*height: 12px;*/
  /*width: 12px;*/
  /*padding-top: 4px;*/
  /*align-items: center;*/
}

.search-patient-MRN {
  text-align: left;
  font: normal normal normal 12px/14px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
  padding-left: 40px;
}

.single-search-item {
  display: block;
}

.load-more {
  padding-bottom: 8px;
}

.noRecordFound {
  height: 128px;
}

.spn-search-not-found-img {
  margin-top: 40px;
}

.spn-search-not-found-txt {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

</style>
