<template>
  <div class="edit-patient mt-2 pb-4 ml-3 mr-3">

    <!-- Top Navigation -->
    <back-button class="ml-4" />
    <!-- Top Navigation Finished-->

    <!--  Edit Patient  -->
    <div class="row header-row ml-0 mr-0 mb-2 pl-3 pr-3 pt-3">
      <h1 class="font-weight-600">Edit Patient</h1>
    </div>
    <!--  Edit Patient Complete  -->

    <!--  Basic Info Card  -->
    <basic-info-card
      :patient="response.patient"
      :loader="loaders.forms"
      ref="patientBasicInfoCard"
      @isPatientDeleted="isPatientDeleted"
      @patientEdited="patientEdited"
    />
    <!--  Basic Info Card Complete  -->

    <!--  Surgery Card  -->
    <surgery-card
      :patient="response.patient"
      :loader="loaders.forms"
      ref="patientSurgeryCard"
      @patientEdited="patientEdited"
    />
    <!--  Surgery Card Complete  -->

    <!--  Weight Card  -->
    <weight-card
      :patient="response.patient"
      :loader="loaders.forms"
      ref="patientWeightCard"
      @patientEdited="patientEdited"
    />
    <!--  Weight Card Complete  -->

    <category-card
      :tags="response.tags"
      :loader="loaders"
      :patient="response.patient"
      ref="patientCategoryCard"
      @patientEdited="patientEdited"
    >
    </category-card>

    <div>
      <base-button class="ml-3 pl-5 pr-5 btn-primary-action" @click="submitEditForm">Update</base-button>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import BasicInfoCard from "@/views/Components/Patients/BasicInfoCard";
import SurgeryCard from "@/views/Components/Patients/SurgeryCard";
import WeightCard from "@/views/Components/Patients/WeightCard";
import CategoryCard from "@/views/Components/Patients/CategoryCard";
import moment from "moment";
import swal from "sweetalert2";

export default {
  name: "EditPatient",
  components: {BackButton, CategoryCard, WeightCard, SurgeryCard, BasicInfoCard},
  data() {
    return {
      params: {
        id: this.$route.params.id,
      },
      response: {
        patient: {
          sub_categories: []
        },
        originalPatientData : {
          sub_categories: []
        },
        tags: [],
      },
      loaders: {
        forms: false,
        categories: false
      },
      forms: {
        basicInfoValidated: false,
        surgeryValidated: false,
        weightValidated: false,
      },
      isUpdated:0,
      isDeleted:0,
      isForced:0,
      patientEditFlag: false
    }
  },
  methods: {
    patientEdited(flag) {
      this.patientEditFlag = flag
    },
    mergeData() {
      let basicInfoCardData = this.$refs.patientBasicInfoCard.$data.form;
      let surgeryCardData = this.$refs.patientSurgeryCard.$data.form;
      let weightCardData = this.$refs.patientWeightCard.$data.form;
      let CategoryCardData = this.$refs.patientCategoryCard.$data.form;
      return {...basicInfoCardData, ...surgeryCardData, ...weightCardData, ...CategoryCardData, ...{id: this.params.id}};
    },

    submitEditForm() {
      let data = this.mergeData();
      this.validateForms();
      if (this.forms.basicInfoValidated === true && this.forms.surgeryValidated === true && this.forms.weightValidated === true) {
        this.editPatient(data , 0);
      }
    },

    validateForms() {
      this.$refs.patientBasicInfoCard.$refs['basicInfo'].validate((valid) => {
        if (valid) {
          this.forms.basicInfoValidated = true;
        } else {
          return false;
        }
      });

      this.$refs.patientSurgeryCard.$refs['surgeryForm'].validate((valid) => {
        if (valid) {
          this.forms.surgeryValidated = true;
        } else {
          return false;
        }
      });

      this.$refs.patientWeightCard.$refs['weightForm'].validate((valid) => {
        if (valid) {
          this.forms.weightValidated = true
        } else {
          return false
        }
      })
    },

    getPatient() {
      let vm = this;
      vm.loaders.forms = true
      axios.get(this.$store.getters.getBaseUrl + '/api/patient-detail?patient_id=' + this.params.id).then((response) => {
        vm.response.originalPatientData = {...response.data.data};
        vm.response.patient = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        vm.loaders.forms = false
      });
    },

    fetchTags() {
      this.loaders.categories = true
      this.loaders.forms = true
      axios.get(`${this.$store.getters.getBaseUrl}/api/user-category/1`)
        .then((response) => {
          this.response.tags = response.data.data
          this.response.tags.empty = false
        })
        .catch((error) => console.error(error.response.message))
        .finally(() => {
          this.loaders.categories = false
          this.getPatient();
        })
    },

    editPatient(data , isForced = 0) {
      let vm = this;
      vm.loaders.forms = true
      data.surgery_date = data.surgery_date ? moment(data.surgery_date).format('YYYY-MM-DD') : '';
      axios.post(this.$store.getters.getBaseUrl + '/api/patient/edit', data).then((response) => {
        vm.$notify.success({
          title: 'Success',
          message: 'Patient profile updated'
        });
        vm.isUpdated = 1;
        vm.isForced = isForced;
        if(vm.isForced == 0){
          this.$router.go(-1);
        }
      }).catch(error => {
        let message = 'Something went wrong please try again in few minutes.';
        if (error.response) {
          let errors = error.response.data.errors;
          let firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        vm.loaders.forms = false
      });
    },

    isPatientDeleted(){
      console.log('isPatientDeleted');
      this.isDeleted = 1;
      // this.$router.go(-1);
      this.$router.push({name: 'ManagePatients'});
    }

  },

  mounted() {
    this.fetchTags()
  },

  beforeRouteLeave (to, from, next) {
    let mergedData= this.mergeData();
    if(this.isUpdated == 1 && this.isForced == 0){
      next()
    }
    else if(this.isDeleted == 1){
      next()
    }
    else {
      if (!this.patientEditFlag) next()
      else {
        swal.fire({
          title: 'Are you sure?',
          text: `Do you want to save your changes?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          buttonsStyling: false
        })
          .then(result => {
            if (result.value) {
              this.validateForms();
              if (this.forms.basicInfoValidated === true && this.forms.surgeryValidated === true && this.forms.weightValidated ===  true) {
                this.editPatient(mergedData, 1);
                next()
              }
            } else {
              next()
            }
          });
      }
    }

  }
}
</script>

<style scoped>

</style>

<style>

.edit-patient .edit-patient-card-headings {
  text-align: left;
  font: normal normal normal 17px/23px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  text-transform: uppercase;
  opacity: 1;
}

.patient-detail-form-container .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.patient-detail-form-container .el-form-item__label {
  line-height: 20px;
  font-weight: 500 !important;
  color: black;
}

.patient-detail-form-container .el-form * {
  font: normal normal normal 14px/19px Open Sans;
}

.patient-detail-form-container .el-input.is-active .el-input__inner,
.patient-detail-form-container .el-input__inner:focus,
.patient-detail-form-container .el-select .el-input.is-focus .el-input__inner,
.patient-detail-form-container .el-select .el-input .el-input__inner:focus {
  border-color: #BDC4CC !important;
  box-shadow: 0 3px 2px #E9ECEF0D;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: '*';
  margin-left: 4px;
}

.back-button-container {
  position: absolute;
  top: 28px
}

.router-link {
  cursor: pointer;
}

</style>
