<template>
  <div class="d-flex align-items-center alert-container h-auto p-1"
    :class="[statusState ? 'is-enabled' : 'is-disabled']"
  >
  <div class="d-flex justify-content-between mx-0 w-100">
      <div class="alert-text-container">
        <h6 v-if="type == 1 && !(title.includes('Hasn\'t logged food') || title.includes('Hasn\'t checked off'))" class="alert-title text-break">
          {{ 'Checked off ' + title }}
        </h6>
        <h6 v-else class="alert-title text-break">{{ title }}</h6>
        <h6 class="alert-date">{{ fromatAlertDate(date) }}</h6>
      </div>
      <img v-if="statusState" class="mr-4 ml-1 alert-delete" src="/img/icons/buttons/remove-no-bg.svg" alt="x"
           @click="handleClick">
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Alert",
  props: ['title','date','status','type'],
  mounted() {
    if (this.status){
      this.statusState = this.status
    }
  },
  data() {
    return {
      statusState: false
    }
  },
  computed:{

  },
  methods:{
    handleClick() {
      this.$emit('click')
      this.statusState = false
    },
    fromatAlertDate(date) {
      let formattedDate = '-';
      if (date) {
          formattedDate = moment(date).format('MMM D, YYYY');
        }
      return formattedDate;
    },
  }
}
</script>

<style scoped>

.alert-container{
  height: 55px;
  border-radius: 6px;
  opacity: 1;
  margin: 15px 0;
}

.alert-text-container {
  padding-left: 1.35rem;
}

.alert-title{
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  margin: 0;
}
.alert-date{
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #797979;
  margin: 0;
}
.alert-delete{
  cursor: pointer;
}
.is-enabled{
  background: #BF93A284 0 0 no-repeat padding-box;
  border: 1px solid #BF93A284;
}

.is-disabled{
  border: 1px solid #BDC4CC80;
}

</style>
