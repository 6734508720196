<template>
  <el-dialog
    title="Edit Patient Value"
    :visible.sync="dialogVisibility"
    width="30rem"
    :before-close="handleClose">
      <el-form :model="add_api_param" :rules="rules" role="form" ref="valueForm">
        <el-form-item prop="value">
          <label class="form-control-label">How much is each patient worth on average, in this pipeline?</label>
          <el-input
                      type="number"
                      v-model="add_api_param.value"
                      placeholder="Value">
          </el-input>
        </el-form-item>
        <div class="text-right mt-4">
          <el-button class="cancel-btn" @click="handleClose">Cancel</el-button>
          <el-button class="move-btn" :disabled="loader" @click="handleSubmit('valueForm')">Update</el-button>
        </div>
      </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "EditPipelineValue",
  props: ['dialogVisibility', 'pipeline'],
  data() {
    const valueValid = (rule, value, callback) => {
      // please remove this should be simply value...however it us undefined for some reason
      if (parseInt(this.add_api_param.value) < 0) {
        callback(new Error('Value should be a positive number.'));
      } else {
        callback();
      }
    };
    return {
      add_api_param: {
        value: '0',
        id: ''
      },
      rules: {
        value: [
          {required: true, message: 'Please input numeric Value', trigger: 'blur'},
          {validator: valueValid, trigger: 'blur'}
        ]
      },
      loader: false,
    };
  },
  mounted() {
    this.add_api_param.value = (this.pipeline.value)?this.pipeline.value.toString():'0'
  },
  watch: {
    pipeline: {
      handler: function (obj) {
        this.add_api_param.value = obj.value.toString() ?? '0';
        this.add_api_param.id = obj.id;
      },
    }
  },
  methods: {

    handleClose(done) {
      this.$emit('closeEditPipelineValueDialog',this.add_api_param.value,this.add_api_param.id)
      this.add_api_param.value = this.pipeline.value
    },
    handleSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.add_api_param.id = this.pipeline.id
          this.updateValueRequest()
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    updateValueRequest() {
      this.loader = true
      axios.put(this.$store.getters.getBaseUrl + '/api/update-pipeline-value', this.add_api_param)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$emit('closeEditPipelineValueDialog',this.add_api_param.value,this.add_api_param.id)
          this.$notify.success({
            title: 'Success',
            message: 'Pipeline updated successfully.'
          });
      }).catch(error => {
        const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        this.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(()=>this.loader = false);
    }
  },
}
</script>

<style scoped>

.move-btn {
  width: 103px;
  height: 45px;
  background: #832C44 0 0 no-repeat padding-box;
  box-shadow: 0 2px 8px #32325D26;
  border-radius: 6px;
  border: 0;
  color: white;
}

.cancel-btn {
  width: 79px;
  height: 45px;
  background: #8898AA 0 0 no-repeat padding-box;
  box-shadow: 0 2px 8px #32325D26;
  border: 0;
  border-radius: 6px;
  color: white;
}

</style>
