<template>
  <div class="pl-3 pr-3">
    <card type="default" header-classes="bg-transparent" v-loading="loader">
      <h4 class="edit-patient-card-headings text-uppercase text-gray-400 font-weight-400 mb-4">Weight</h4>
      <div class="patient-detail-form-container" id="weight-form-container">
        <el-form label-position="top" ref="weightForm" :model="form" :rules="rules" label-width="120px">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <el-form-item label="Starting Weight (LBS)" prop="starting_weight">
                <el-input
                  placeholder="Starting Weight"
                  v-model="form.starting_weight"
                  @change="weightValuesChanged('starting_weight')"/>
              </el-form-item>
            </div>

            <div class="col-xs-12 col-md-6">
              <el-form-item label="Target Weight (LBS)" prop="target_weight">
                <el-input
                  placeholder="Target Weight"
                  v-model="form.target_weight"
                  @change="weightValuesChanged('target_weight')"/>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  name: "WeightCard",
  props: ['patient', 'loader'],
  data() {
    const validInputWeight = (rule, value, callback) => {
        callback()
    }
    return {
      form: {
        starting_weight: '',
        target_weight: '',
      },
      rules: {
        starting_weight: [
          {validator: validInputWeight, trigger: 'blur'}
        ],
        target_weight: [
          {validator: validInputWeight, trigger: 'blur'}
        ],
      }
    }
  },
  watch: {
    patient(newVal) {
      this.assignValues(newVal)
    }
  },
  methods: {
    assignValues(patient) {
      this.form.starting_weight = patient.starting_weight;
      this.form.target_weight = patient.target_weight;
    },
    weightValuesChanged(field) {
      this.$emit('patientEdited', this.patient[field] != this.form[field] ? true : false)
    },
  }
}
</script>

<style scoped>

</style>
