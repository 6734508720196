export default {
  methods: {
    changeSelectWidth(className, propClassName) {
      let props = document.getElementsByClassName(propClassName);
      let selectedItem = '';
      this.$nextTick(() => {
        for (let i = 0; i < props.length; i++) {
          let prop = props[i].childNodes[0];
          prop = prop.childNodes[0]
          prop = prop.childNodes[0]
          for (let j = 0; j < prop.childNodes.length; j++) {
            let childNode = prop.childNodes[j];
            let classes = childNode.className !== undefined ? childNode.className.split(" ") : [];
            for (let k = 0, jl = classes.length; k < jl; k++) {
              if (classes[k] === 'selected') {
                selectedItem = childNode.childNodes[0];
                break;
              }
            }
          }
          if (selectedItem) {
            let length = selectedItem.innerHTML.length * 7.3 + 6;
            let select = document.getElementsByClassName(className);
            for (let j = 0; j < select.length; j++) {
              let node = select[j];
              node = node.childNodes[1];
              node = node.childNodes[1];
              node.style.width = `${length}px`
            }
          }
        }
      })
    },
    changeSelectWidthDefault(className, width) {
      this.$nextTick(() => {
        let select = document.getElementsByClassName(className);
        for (let j = 0; j < select.length; j++) {
          let node = select[j];
          node = node.childNodes[1];
          node = node.childNodes[1];
          node.style.width = `${width}px`
        }
      })
    },
  }
}
