<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <route-back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="modals">
      <modal :show.sync="modals.changeStageConfirmationModal" class="change-stage-confirmation-modal">
        <!-- Notification Modal Header -->
        <template slot="header">
          <h4 class="modal-title font-weight-bold mb-0 pt-0 pb-0" id="changeStageConfirmationModal">Are you sure?</h4>
        </template>
        <template slot="default">
          <p class="m-0 p-0 confirmation-message">You want to move this patient to this stage?</p>
        </template>
        <template slot="footer">
          <div class="m-0 p-0 footer-button">
            <base-button class="btn-gray pl-4 pr-4" @click="modals.changeStageConfirmationModal=false">No</base-button>
            <base-button class="btn-pink pl-4 pr-4" @click="onConfirmChangeStageModal">
              Yes
            </base-button>
          </div>
        </template>
      </modal>
    </div>
    <div class="container-fluid d-flex justify-content-end align-items-center mt-4 mb-4">
      <base-button
        class="white-outline-button title-btn-padding button-hover-effect button-font"
        @click="resendLoginDetails"
        :disabled="loaders.email">
        Resend Login Email
      </base-button>
      <router-link class="router-link pr-2" :to="{name: 'NutritionReport' , params: { id: api_param.patient_id } }">
        <base-button type="secondary" class="white-outline-button title-btn-padding button-hover-effect button-font">
          Nutrition Report
        </base-button>
      </router-link>
      <router-link :to="{name: 'checklists', params: {id: api_param.patient_id}}">
        <base-button class="btn-secondary-action title-btn-padding button-hover-effect button-font">Checklist</base-button>
      </router-link>
    </div>
    <div class="container-fluid d-flex justify-content-center mt-4 mb-4">
        <ul class="breadcrumb wizard">
          <li v-for="(pbStage,key) in pbStages" :class="pbStage.order_key<=pbCurrentStage?'active':''" :style="{ maxWidth: pbStageMaxLength + '%' }">
            <a href="javascript:void(0);" class="pb-internal-a" @click="toggleChangeStageModal(pbStage)">
              {{pbStage.title}}
              <span class="pb-internal-span">{{pbStage.days}} Days</span>
            </a>
          </li>
        </ul>
    </div>
    <div class="mt-1 row px-3 mx-0">
      <div class="col-6">
        <card class="no-border-card equal-height-columns" body-classes="px-0 pb-1" footer-classes="pb-2"
              id="basic-info-card" v-loading="loaders.card">
          <div slot="header" class="position-relative">
            <h1 style="min-height: 40px " class="mt-4 ml-2 patient-name">
              {{ patient_data.first_name }}
              {{ patient_data.last_name }}
            </h1>
            <div class="d-flex flex-row">
              <p class="mb-0 ml-2" id="patient-email">
                {{ patient_data.email }}
              </p>
              <p class="ml-2 patient-status pl-3 pr-3 pt-1 pb-1"
                 :class="patient_data.status === 0 ? 'inactive' : 'active'">
                {{ getStatus }}
              </p>
            </div>
            <router-link :to="{name: 'EditPatientDetail', params: {id: api_param.patient_id}}">
              <base-button
                class="position-absolute black-outline-button pt-2 pb-2 custom-button font-weight-normal"
                id="edit-user-details-button">
                Edit
              </base-button>
            </router-link>
          </div>

          <h2 style="border-top: 1px solid rgba(0, 0, 0, 0.05)" class="mt-3 pl-4 pt-4 patient-detail-heading">BASIC
            INFO</h2>

          <div class="row pt-2 pl-4">
            <div class="col-6">
              <p class="patient-detail-text">DOB</p>
              <p class="patient-detail-text">Phone</p>
              <p class="patient-detail-text">Height</p>
              <p class="patient-detail-text">Join Date</p>
              <p class="patient-detail-text">Last Login</p>
              <p class="patient-detail-text">Last Push Notification</p>
              <p class="patient-detail-text">MRN Number</p>
              <p class="patient-detail-text">Created By</p>
            </div>
            <div v-if="patient_data != []" class="col-6">
              <p class="black_p">{{ patient_data.dob ? formatDate(patient_data.dob) : '-' }}</p>
              <p class="black_p">{{ patient_data.phone ? patient_data.phone : '-' }}</p>
              <p class="black_p">{{ patient_data.hight ? patient_data.hight : '-' }}</p>
              <p class="black_p">{{ getProgramJoinDate }}</p>
              <p class="black_p">{{ patient_data.last_used ? formatDate(patient_data.last_used) : '-' }}</p>
              <p class="black_p">
                {{ patient_data.last_push_notification ? formatDate(patient_data.last_push_notification) : '-' }}
              </p>
              <p class="black_p">
                {{ patient_data.mrn_number ? patient_data.mrn_number : '-' }}
              </p>
              <p class="black_p">{{ patient_data.created_by ? patient_data.created_by : 'N/A' }}</p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loaders.card">
          <div slot="header">
            <h2 class="mt-2 patient-detail-heading">WEIGHT</h2>
          </div>

          <div class="row mt--4 pl-4">
            <div class="col-6 pl-3">
              <p class="patient-detail-text">Starting Weight</p>
              <p class="patient-detail-text">Current Weight</p>
              <p class="patient-detail-text">Target Weight</p>
            </div>
            <div class="col-6 pt-0 pl-3">
              <p class="black_p">
                {{ getStartingWeight }}
              </p>
              <p class="black_p">
                {{ getCurrentWeight }}
              </p>
              <p class="black_p mb-4">
                {{ getTargetWeight }}
              </p>
            </div>
          </div>

        </card>

        <card class="no-border-card" body-classes="px-0 pb-3" footer-classes="pb-2" v-loading="loaders.card">
          <div slot="header">
            <h2 class="mt-2 patient-detail-heading">SURGERY</h2>
          </div>
          <div class="row mt--4 pl-4">
            <div class="col-6 pt-2 pl-3">
              <p class="patient-detail-text">Type Of Surgery</p>
              <p class="patient-detail-text">Initial Stage</p>
              <p class="patient-detail-text">Current Stage</p>
              <p class="patient-detail-text">Surgery Date</p>
            </div>
            <div class="col-6 pt-1">
              <p class="black_p">
                {{ patient_data.type_of_surgery ? patient_data.type_of_surgery : '-' }}
              </p>
              <p class="black_p">
                {{ getNameOfStage(patient_data.inital_stage) }}
              </p>
              <p class="black_p">
                {{ getNameOfStage(patient_data.current_stage) }}
              </p>
              <p class="black_p">
                {{ patient_data.surgery_date ? formatDate(patient_data.surgery_date) : '-' }}
              </p>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="m-0 px-3 row">
      <div class="col-md-6">
        <card v-loading="loaders.alerts">
          <h4 class="text-uppercase ls-1 mb-3 pt-1 font-weight-700 text-uppercase">Alerts</h4>
          <div class="scrollable mb-1" style="height: 401px">
            <template v-if="alerts.pagination.data.length >0">
              <template v-for="alert of alerts.pagination.data">
                <alert
                  :key="alert.id"
                  :title="alert.alert_msg"
                  :date="alert.date"
                  :type="alert.type"
                  :status="alert.status"
                  @click="dismissEvent(alert)"
                >
                </alert>
              </template>
            </template>
            <div v-else class="d-flex h-100 align-items-center">
              <h1 :class="{invisible: loaders.alerts}" class="empty-data-text-font">No alerts, all clear!</h1>
            </div>
          </div>
          <notes-footer :notes="alerts"
                        :default-pagination="5"
                        :pagination-values="[5,10,20,30,50]"
                        @changePage="changePage" @changedPagination="changedPagination"/>
        </card>
      </div>
      <div class="col-md-6">
        <notes screen="PatientDetail"/>
      </div>
    </div>

    <div class="patient-detail-messages">
      <patient-detail-messages/>
    </div>
  </div>
</template>
<script>

import Notes from "@/views/Components/Notes/Notes";
import PatientDetailMessages from "@/views/Components/Patients/PatientDetail/PatientDetailMessages";
import moment from 'moment'
import BackButton from "@/components/Router/BackButton";
import NotesFooter from "../../Components/Notes/NotesFooter";
import Alert from '@/views/Components/Alerts/Alert.vue'

export default {

  props: ['patient_detail'],
  components: {
    NotesFooter,
    Notes,
    RouteBackButton: BackButton,
    PatientDetailMessages,
    Alert
  },
  data() {
    return {
      loaders: {
        card: false,
        email: false,
        alerts: false
      },
      modals : {
        changeStageConfirmationModal:false,
      },
      api_param: {
        patient_id: this.$route.params.id,
      },
      alerts: {
        pagination: {
          current_page: 1,
          data: [],
          next_page_url: null,
          path: "",
          per_page: 5,
          prev_page_url: null,
          to: 5,
          total: 0
        },
        page : 1,
        paginate : 5,
      },
      patient_data: {
        first_name: '',
        last_name: '',
        email: '',
        weights: [''],
      },
      current_weight: '',
      inital_stage: '',
      dob: '',
      target_weight: '',
      starting_weight: '',
      last_push_notification: '',
      mrn_number: '',
      stages: {
        25: 'Considering Surgery',
        50: 'Pre-Op',
        75: 'Post-Op',
        100: 'Post-Op',
        125: 'Post-Op',
        150: 'Non-Surgical',
      },
      activeClass:'active',
      pbCurrentStage:1,
      pbStages : [],
      pbStageMaxLength : 1,
      currentStage: {}
    };
  },
  created() {
    this.getPatientDetailApiCall();
  },
  mounted() {
    this.getStagesWithDays();
    this.patientAlertsRequest()
  },
  methods: {

    changePage(page) {
      this.alerts.page = page;
      this.patientAlertsRequest()
    },

    changedPagination(pagination) {
      this.alerts.paginate = pagination;
      this.patientAlertsRequest()
    },

    dismissEvent(alert) {
      console.log(alert)
      this.dismissAlertRequest(alert.id)
    },

    getNameOfStage(stage) {
      if (!stage) {
        return '-'
      }
      return this.stages[stage];
    },

    getPatientDetailApiCall() {
      let vm = this;
      vm.loaders.card = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/patient-detail?patient_id=' + this.api_param.patient_id).then((response) => {
        vm.patient_data = response.data.data;

      }).catch(error => {
        if (error?.response?.status == 404) {
          this.$router.push("/not-found")
        }
        console.log(error.response.data.message)
      }).finally(() => {
        vm.loaders.card = false;
      });
    },

    getWeights(weights) {
      var max_index = '';
      weights.data.forEach((weight, index) => {
        max_index = index;
      });

      this.current_weight = weights[0].weight;
      this.starting_weight = weights[max_index].weight;
    },

    formatDate(date) {
      return moment(date,'MM/DD/YYYY').format('MMM D, YYYY');
    },

    resendLoginDetails() {
      let vm = this;
      this.loaders.email = true;
      axios.post(this.$store.getters.getBaseUrl + '/api/send-password-reset-email', {
        patient_id: vm.patient_data.id,
      }).then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Login email sent'
        });
      }).catch(error => {
        var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        vm.loaders.email = false;
      })
    },

    patientAlertsRequest() {
      this.loaders.alerts = true
      let config = {
        params: {
          page: this.alerts.page,
          pagination: this.alerts.paginate
        },
      }
      axios.get(this.$store.getters.getBaseUrl + `/api/alerts/${this.api_param.patient_id}`,config)
        .then((response)=> this.alerts.pagination = response.data.data)
        .catch((error)=>{
          this.$notify.error({
            title: 'Alerts',
            message: 'Something Went Wrong Try again later'
          })
        })
        .finally(()=>this.loaders.alerts = false)
    },

    dismissAlertRequest(id) {
      const payload = {
        id: id,
        _method: 'PUT'
      }
      axios.post(this.$store.getters.getBaseUrl +'/api/alerts/delete',payload)
        .catch((error)=> {
          this.$notify.error({
            title: 'Dismiss Failed',
            message: 'Something Went Wrong Try again later'
          })
        })
    },

    toggleChangeStageModal(element){
      this.currentStage = element;
      this.modals.changeStageConfirmationModal = !this.modals.changeStageConfirmationModal;
    },

    onConfirmChangeStageModal(){
      let vm = this;
      axios.post(vm.$store.getters.getBaseUrl + '/api/update-patients-pipeline-stage?patientId=' + vm.api_param.patient_id + '&stageId=' + vm.currentStage.id).then((response) => {
        console.log(response.data.data);
        vm.modals.changeStageConfirmationModal = !vm.modals.changeStageConfirmationModal;
        vm.getStagesWithDays();
      }).catch(error => {
        console.error(error.response);
      }).finally(() => {
      });
    },

    getStagesWithDays(){
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/patient-pipeline-stage-days?patientId=' + this.api_param.patient_id).then((response) => {
        vm.pbCurrentStage = response.data.data.pipeline_stage.order_key;
        vm.pbStages = response.data.data.stages;
        vm.pbStageMaxLength = (100/Object.keys(response.data.data.stages).length);
      }).catch(error => {
        console.error(error.response);
      }).finally(() => {
      });
    },

  },
  computed: {
    getStatus() {
      return this.patient_data.status === 0 ? 'Inactive' : 'Active';
    },
    getStartingWeight() {
      return this.patient_data.starting_weight ? (+this.patient_data.starting_weight).toFixed(2) : '-';
    },
    getCurrentWeight() {
      return this.patient_data.current_weight ? (+this.patient_data.current_weight).toFixed(2) : '-';
    },
    getTargetWeight() {
      return this.patient_data.target_weight ? (+this.patient_data.target_weight).toFixed(2) : '-'
    },
    getProgramJoinDate() {
      let date = '-';
      if (this.patient_data && this.patient_data.program_join_date) {
          date = moment(this.patient_data.program_join_date, 'YYYY-MM-DD').format('MMM D, YYYY');
      }
      return date;
    },

  },
  watch : {
  }
};
</script>

<style scoped>
.patient-details-container {
  padding-right: 0 !important;
}

#edit-user-details-button {
  top: -20px;
  right: 0;
  padding-left: 37px;
  padding-right: 37px;
}

.patient-name {
  text-align: left;
  font: normal normal 600 25px/34px Open Sans;
  letter-spacing: 0;
  color: #32325D;
  opacity: 1;
}

#patient-email {
  text-align: left;
  font: normal normal 600 17px/23px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  opacity: 1;
}

.patient-status {
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BB2255 0 0 no-repeat padding-box;
}

.patient-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.patient-detail-text {
  text-align: left;
  font: normal normal normal 15px/19px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  padding-bottom: 2px;
  opacity: 1;
}

.internal-notes-container, .patient-detail-messages {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-button {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 4px 6px #32325D1C;
  border-radius: 6px;
}

.scrollable{
  overflow-y: auto;
}
</style>

<style>
.no-border-card .card-footer {
  border-top: 0;
}

#basic-info-card .card-body {
  padding-top: 0 !important;
}

#basic-info-card .card-header {
  padding-bottom: 0 !important;
}

.card-header {
  border: none;
}

.change-stage-confirmation-modal .modal-body {
  padding-top: 0px;
  padding-bottom: 30px;
}
.change-stage-confirmation-modal .modal-header {
  padding-bottom: 0px;
}

.confirmation-message {
  width: 285px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}
/*
Progress Bar
*/

.breadcrumb.wizard {
  padding: 0px;
  background: #94B527;
  list-style: none;
  overflow: hidden;
  margin-top: 20px;
  font-size: 10px;
  width: 100%;
}
.breadcrumb.wizard>li+li:before {
  padding: 0;
}
.breadcrumb.wizard li {
  float: unset;
  min-height: 62px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background: white;
}
.breadcrumb.wizard li.active{
  background: #832C44;
}
.breadcrumb.wizard li a {
  text-decoration: none;
  padding: 10px 0 10px 45px;
  position: relative;
  display: block;
  width:100%;

  text-align: left;
  font: normal normal 600 11px/15px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}
.breadcrumb.wizard li.active a{
  color: white;
}
.breadcrumb.wizard li:not(:last-child) a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.breadcrumb.wizard li:not(:last-child) a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid black;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
  opacity: .2;
}
.breadcrumb.wizard li.active a:after {
  border-left: 30px solid #832C44;
}

.breadcrumb.wizard li:first-child a {
  padding-left: 15px;
}
.pb-internal-span {
  display: block;
  width: 100%;
  text-align: left;
  font: normal normal bold 14px/19px Open Sans;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  padding-top: 6px;
}
.pb-internal-a {

}

.btn-pink, .footer-button .btn-pink:hover, .footer-button .btn-pink:active {
  color: white;
  background: #F5365C;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  opacity: 1;
}


.btn-gray, .footer-button .btn-gray:hover, .footer-button .btn-gray:active{
  color: white;
  background: #8898AA;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  opacity: 1;
}

.change-stage-confirmation-modal .modal-body {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
