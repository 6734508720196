<template>
  <div class="pl-3 pr-3">
    <card type="default" header-classes="bg-transparent" v-loading="loader.tags || loader.forms">
      <h4 class="edit-patient-card-headings text-uppercase text-gray-400 font-weight-400 mb-4">Patient Tags</h4>
      <div class="patient-detail-form-container" id="category-form-container">
        <el-form ref="surgeryForm" label-position="top" label-width="120px">
          <div class="row">
            <div v-if="categoriesEmpty" class="col text-center">
              <h1>No Tags found</h1>
            </div>
            <template v-else>
              <div v-for="(tag) in tags"
                   :key="`category${tag.id}`"
                   class="col-xs-12 col-md-6">
                <el-form-item
                  :label="tag.name" :prop="formState[`${tag.id}`]"
                >
                  <el-select
                    class="w-100 select-primary pagination-select"
                    v-model="formState[`${tag.id}`]"
                    placeholder="Select a Tag Name"
                    @change="prepareForm"
                    :clearable="true"
                    @clear="removeState(tag.id)"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in tag.user_sub_categories"
                      :key="item.name+item.id"
                      :label="item.name"
                      :value="item.id.toString()"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </div>
        </el-form>
      </div>
    </card>
  </div>

</template>

<script>

export default {
  name: "CategoryCard",
  props: ['tags', 'loader', 'patient'],
  computed: {
    categoriesEmpty() {
      return (this.tags &&
          Object.keys(this.tags).length === 0 &&
          this.tags.constructor === Object) ||
        this.tags.length <= 0
    }
  },
  data() {
    return {
      formState: {},
      tempFormState: {},
      form: {
        subCategories: [],
        categoryCount: 0
      }

    }
  },
  watch: {
    patient(newVal) {
      if (!((newVal &&
          Object.keys(newVal).length === 0 &&
          newVal.constructor === Object) ||
        newVal.length <= 0)) {
        this.updateFormState(newVal)
        this.prepareForm()
      }
    },
  },
  methods: {
    tagsChanged() {
      if (_.isEqual(this.tempFormState, this.formState)) {
        this.$emit('patientEdited', false)
      } else {
        this.$emit('patientEdited', true)
      }
    },
    removeState(id) {
      delete this.formState[id]
      this.prepareForm()
    },

    updateFormState(patient) {
      let iterable = Object.keys(this.tags)
      iterable = iterable.slice(0, iterable.length - 1)
      for (let i = 0; i < iterable.length; ++i) {
        for (let j = 0; j < patient.sub_categories.length; ++j) {
          if (this.tags[i].id === patient.sub_categories[`${j}`].user_category_id) {
            this.$set(this.formState, `${this.tags[i].id}`, patient.sub_categories[`${j}`].id.toString())
            this.$set(this.tempFormState, `${this.tags[i].id}`, patient.sub_categories[`${j}`].id.toString())
            break
          }
        }
      }
    },
    getTagNames(id1, id2) {
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].id === parseInt(id1)) {
          for (let j = 0; j < this.tags[i].sub_category.length; j++) {
            if (this.tags[i].user_sub_categories[j].id === parseInt(id2))
              return [this.tags[i].user_sub_categories[j].name, this.tags[i].name]
          }
        }
      }
      return ['', '']
    },
    prepareForm() {
      const subCategoryKeys = [
        'patient_id', 'user_category_id', 'user_sub_category_id',
        'user_sub_category_name', 'user_category_name'
      ]
      const keys = Object.keys(this.formState)
      const count = keys.length
      this.form['categoryCount'] = count
      const newSubcategoryArray = []
      for (let i = 0; i < count; i++) {
        newSubcategoryArray.push(
          _.zipObject(subCategoryKeys, [...[this.patient.id,
            parseInt(keys[i]),
            parseInt(this.formState[`${keys[i]}`])],
            ...this.getTagNames(keys[i], this.formState[`${keys[i]}`])
          ])
        )
      }
      this.form.subCategories = newSubcategoryArray
      this.tagsChanged()
    }
  }
}
</script>

<style scoped>

</style>

<style>
.masked-date-input {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}
</style>
