<template>
  <!-- Delete Modal -->
  <modal :show.sync="$store.state.modals.markAsLostModalVisibility"
  @close="hideModal"
  >
    <template slot="header">
      <h3 class="modal-title">Mark as Lost ( {{getPatientName}} )</h3>
    </template>
    <div class="content mt--4 ml--1">
      <validation-observer v-slot="{handleSubmit}" ref="markAsLostForm">
        <form class="needs-validation" @submit.prevent="handleSubmit(onMarkAsLost)">

          <base-input
            label="Reason"
            name="Reason"
            :rules="{ required: true }"
          >
            <el-select placeholder="Select Reason"
                       v-model="request.lost.reason"
                       class="drpdwn-lost-reason"
                       filterable>
              <el-option v-for="lostReason in request.lostReasons"
                         class="select-danger"
                         :value="lostReason.value"
                         :label="lostReason.label"
                         :key="lostReason.label">
              </el-option>
            </el-select>
          </base-input>
          <div class="mt-4">
            <base-input
              label="Comments"
              name="comments"
            >
              <textarea v-model="request.lost.comments" class="form-control" rows="4">
              </textarea>
            </base-input>
          </div>

          <div class="float-right mt-4">
            <base-button
              type="secondary"
              class="ml-auto btn-gray"
              @click="hideModal"
            >
              Cancel
            </base-button>
            <base-button class="btn-primary-action" native-type="submit" type="danger">Remove</base-button>
          </div>
        </form>
      </validation-observer>

    </div>
  </modal>
</template>
<script>
export default {
  props: ['pipelines', 'pipeline_patient'],
  data() {
    return {
      request: {
        lost: {
          reason: "",
          comments: "",
        },
        lostReasons : [
          {value: 'Lost contact with patient', label: 'Lost contact with patient'},
          {value: 'Denied by insurance', label: 'Denied by insurance'},
          {value: 'No longer want to have weight loss surgery', label: 'No longer want to have weight loss surgery'},
          {value: 'Unhappy with program', label: 'Unhappy with program'},
          {value: 'Does not meet criteria', label: 'Does not meet criteria'},
          {value: 'Out of network insurance', label: 'Out of network insurance'},
          {value: 'Other', label: 'Other'},
        ],
        changeStage: {
          stage_id: '',
          patient: this.pipeline_patient,
          pipeline_id: '',
          stage_index: '',
        },
      },
      lostPipeline: {}
    };
  },
  computed: {
    getPatientName() {

      return (this.pipeline_patient.first_name??'') +' '+ (this.pipeline_patient.last_name??'')
    },
    getLostPipeline() {
      return this.pipelines.find(pipeline => pipeline.title === 'Lost-Patients');
    },
    getUnassignedStage() {
      return this.lostPipeline.stages.find(stage => stage.title === 'Unassigned');
    },
    getUnassignedStageIndex() {
      return this.lostPipeline.stages.findIndex(stage => stage.title === 'Unassigned');
    }
  },
  methods: {
    hideModal() {
      this.$refs.markAsLostForm.reset();
      this.clearFormFields();
      this.$emit("onCancelMarkAsLost", this.pipeline_patient);
      this.$store.commit('hideMarkAsLostModal');
    },
    clearFormFields() {
      this.request.lost.reason = '';
      this.request.lost.comments = '';
    },
    onMarkAsLost() {
      this.setLostPipeline();
      this.setChangeStage();
      this.$emit("onMarkAsLost", this.request);
    },

    setLostPipeline() {
      this.lostPipeline = this.getLostPipeline;
    },

    setChangeStage() {
      this.request.changeStage.stage_id = this.getUnassignedStage.id;
      this.request.changeStage.patient = this.pipeline_patient,
      this.request.changeStage.pipeline_id = this.lostPipeline.id;
      this.request.changeStage.stage_index = this.getUnassignedStageIndex;
    }
  },
};
</script>

<style scoped>

.drpdwn-lost-reason {
  width: 100%;
}
</style>
