<template>
  <span>
    <span class="mx-2">is</span>
    <el-select
      class="select-primary checklist-percentage"
      allow-create
      filterable
      v-model=value.percentage
      placeholder="Percentage"
      :class="[value.percentage ? `option-selected` : '',]"
      popper-class="checklist-percentage-dropdown"
      @change="changeSelect"
    >
      <el-option
        class="select-primary"
        v-for="(checklist , index) in percentageList"
        :key="index+'check_list'"
        :label="checklist.label"
        :value="checklist.value"
      >
      </el-option>
    </el-select>
    <span class="mx-2">% completed of Patient in</span>
    <el-select
      class="select-primary current-stage-type mr-2"
      v-model=value.current_stage_type
      placeholder="stage type"
      :class="[value.current_stage_type ? `option-selected` : '',]"
      popper-class="current-stage-type-dropdown"
      @change="changeSelectWidth('current-stage-type','current-stage-type-dropdown')"
    >
      <el-option
        class="select-primary"
        v-for="(stage , index) in currentStageType"
        :key="index+'current_stage_type'"
        :label="stage.label"
        :value="stage.value"
      >
      </el-option>
    </el-select>

    <el-select
      v-if="value.current_stage_type!=null && value.current_stage_type!==1"
      class="select-primary checklist-percentage-pipeline-stage mr-2"
      v-model=value.current_stage_id
      placeholder="Pipeline-Stage"
      :class="[value.current_stage_id ? `option-selected` : '',]"
      popper-class="checklist-percentage-pipeline-stage-dropdown"
      @change="changeSelectWidth('checklist-percentage-pipeline-stage','checklist-percentage-pipeline-stage-dropdown')"
    >
      <el-option
        class="select-primary"
        v-for="(stage , index) in stages"
        :key="index+'pipeine_stage'"
        :label="stage.title"
        :value="stage.id"
      >
      </el-option>
    </el-select>

  </span>
</template>

<script>
import pipelineAutomationRuleMixin from "../../../../mixins/pipelineAutomationRuleMixin";
import {constants} from "../../../../util/constants";
export default {
  mixins: [pipelineAutomationRuleMixin],
  props: ['value', 'filteredChecklists', 'stages'],
  data() {
    return {
      percentageList: [
        {value: 25, label: '25'},
        {value: 50, label: '50'},
        {value: 75, label: '75'},
        {value: 100, label: '100'}
      ],
      currentStageType: [
        {label: 'any stage', value: constants.pipeline.currentPipelineStageType.ANY_STAGE},
        {label: 'this stage', value: constants.pipeline.currentPipelineStageType.THIS_STAGE},
        {label: 'after this stage', value: constants.pipeline.currentPipelineStageType.AFTER_THIS_STAGE}
      ]
    }
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(input) {
        this.$emit('input', input)
      }
    }
  },
  watch: {},
  methods: {
    changeSelect() {
      if (this.value.percentage < 0 || this.value.percentage > 100) {
        this.value.percentage = null;
        this.showError('Percentage must in between 0 and 100 ')
      }
      this.changeSelectWidth('checklist-percentage', 'checklist-percentage-dropdown')
    },
    showError(message) {
      this.$notify.error({
        title: 'Error',
        message: message
      });
    },
  }
};
</script>

<style lang="scss">
.pipeline-automation-rule-dialog {
  .checklist-percentage-pipeline-stage {
    .el-input {
      .el-input__inner {
        width: 108px;
        padding-right: 0;
      }
    }
  }

  .current-stage-type {
    .el-input {
      .el-input__inner {
        width: 80px;
        padding-right: 0;
      }
    }
  }

  .checklist-percentage {
    .el-input {
      .el-input__inner {
        width: 77px;
        padding-right: 0;
      }
    }
  }
}
</style>
