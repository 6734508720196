<template>
  <div v-loading="loaders.mainLoading">
    <div class="w-100 h-100">
      <div class="row justify-content-between align-items-center m-0 pt-4 pb-3">
        <h1 class="ml-0 mb-0 d-inline-block">Automation</h1>
        <base-button class="mr-3 btn-secondary-action button-font title-btn-padding button-hover-effect border-none"
                     type="secondary" @click="openAddPipelineAutomationRuleDialog">Add New Automation
        </base-button>
      </div>
      <div :key="key">
        <draggable group="all-rules" ghost-class="moving-card" filter=".action-button" handle=".handle"
                   class="w-full max-w-md delete_patient_area " :list="response.pipelineAutomationRuleList" :animation="200" @change="changeRulePriority">
          <pipeline-automation-rule-item
            v-for="(rule,index) in response.pipelineAutomationRuleList"
            :key="`${rule.id}pipelineAutomationRuleListItem`"
            :rule-item="rule"
            :pipeline-title="pipelineTitle"
            :index = "index"
            :style="{ marginBottom: response.pipelineAutomationRuleList[index+1]? '20px':'0px' }"
            @toggleActive="toggleActive"
            @deletePipelineAutomationRule="deletePipelineAutomationRule"
            @editPipelineAutomationRule="editPipelineAutomationRule"
          />
        </draggable>
      </div>
    </div>
    <add-pipeline-automation-rule-dialog
      :pipeline-id="pipelineId"
      ref="addPipelineAutomationRuleDialog"
      @updatePipelineAutomationRuleList="updatePipelineAutomationRuleList"
      :addPipelineAutomationRuleDialogVisible="addPipelineAutomationRuleDialogVisible"
      @closeAddPipelineAutomationRuleDialog="addPipelineAutomationRuleDialogVisible=false"/>
  </div>
  </template>
<script>

  import Draggable from 'vuedraggable'
  import PipelineAutomationRuleItem from "../../Components/Pipeline/PipelineAutomationRuleItem";
  import AddPipelineAutomationRuleDialog from "./AddPipelineAutomationRuleDialog";
  import {constants} from "../../../util/constants"

  export default {
    components: {
      AddPipelineAutomationRuleDialog,
      PipelineAutomationRuleItem,
      Draggable
    },
    props:{
      pipelineId: {
        type: Number,
        default: null,
        description: 'Pipeline id'
      },
      pipelineTitle: {
        type: String,
        default: null,
        description: 'Pipeline name'
      }
    },
    data() {
      return {
        addPipelineAutomationRuleDialogVisible: false,
        response :{
          pipelineAutomationRuleList:[]
        },
        loaders :{
          mainLoading:false
        },
        key:1234
      };
    },
    methods: {
      changeRulePriority(event) {
        let ruleIndex = this.response.pipelineAutomationRuleList.findIndex(rule => rule.id === event.moved.element.id)
        if (ruleIndex !== -1) {
          let ruleId = this.response.pipelineAutomationRuleList[ruleIndex].id;
          let params = {
            pipeline_id: this.pipelineId,
            id: ruleId,
            oldOrderKey: event.moved.oldIndex + 1,
            newOrderKey: event.moved.newIndex + 1
          }
          this.$store.dispatch('pipelineModule/_updatePriorityAutomationRule', params).then(response => {
            this.$notify.success({
              title: 'Pipeline Automation Rule Priority',
              message: response.data.message
            });
          }).catch(err => {
          });
        }
      },

      openAddPipelineAutomationRuleDialog() {
        this.addPipelineAutomationRuleDialogVisible = true;
      },

      closeAddPipelineAutomationRuleDialog() {
        this.addPipelineAutomationRuleDialogVisible = false;
      },

      deletePipelineAutomationRule(id) {
        let data = {
          id: id,
          pipeline_id: this.pipelineId
        }
        this.$emit('deletePipelineAutomationRule', data);
      },
      editPipelineAutomationRule(rule) {
        this.$refs.addPipelineAutomationRuleDialog.editPipelineRule = true;
        this.openAddPipelineAutomationRuleDialog();
        this.$refs.addPipelineAutomationRuleDialog.editPipelineAutomationRule(rule);
      },

      removeRuleFromList(id) {
        let index = this.response.pipelineAutomationRuleList.findIndex(item => item.id === id);
        if (index !== -1) {
          let rule = this.response.pipelineAutomationRuleList[index];
          this.response.pipelineAutomationRuleList.splice(index, 1);
          if (rule.rule_type === constants.pipeline.automationRule.CHECKLIST) {
            this.$refs.addPipelineAutomationRuleDialog.addChecklistInChecklists({
              id: 0,
              description: rule.new_todo_setting_description,
              temp_stage: rule.temp_stage
            });
            this.$refs.addPipelineAutomationRuleDialog.editIncludedChecklist = null;
          }
        }
      },

      toggleActive(data) {
        let vm = this;
        let params = {
          id: data.id,
          active: data.active
        }
        this.$store.dispatch('pipelineModule/_toggleActiveAutomationRule', params).then(response => {
          vm.$set(this.response.pipelineAutomationRuleList[data.index], 'active', data.active);
          vm.$notify.success({
            title: 'Pipeline Automation Rule',
            message: response.data.message
          });
        }).catch(err => {
        });
      },

      getPipelineAutomationRuleList() {
        let vm = this;
        vm.loaders.mainLoading = true;
        axios.get(this.$store.getters.getBaseUrl + `/api/pipeline-automation-rule?pipeline_id=${vm.pipelineId}`)
          .then(response => {
            vm.$store.commit('toggleSessionCheck', true)
            vm.$store.commit('updateSessionTime', 0)
            vm.response.pipelineAutomationRuleList = response.data.data;
          }).catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Pipeline Automation Rules Retrieval',
            message: message
          });
        }).finally(() => {
          vm.loaders.mainLoading = false;
        });
      },

      updatePipelineAutomationRuleList(rule) {
        let index = this.response.pipelineAutomationRuleList.findIndex(item => item.id === rule.id);
        if (index === -1) {
          this.response.pipelineAutomationRuleList.push(rule);
          this.$set(this.response.pipelineAutomationRuleList);
        } else {
          this.$set(this.response.pipelineAutomationRuleList, index, rule);
          this.key = Math.random();
        }
      }
    },
    computed:{
      refreshListing(){
        return this.$store.getters['pipelineModule/getRefreshPipelineAutomationRuleListing']
      }
    },
    watch:{
      refreshListing (newVal, oldVal) {
        if(newVal){
          this.getPipelineAutomationRuleList();
        }
      }
    },
    created() {
      this.getPipelineAutomationRuleList();
    }
  };
</script>
<style lang="scss" scoped>
  .add_pipeline_btn {
    background-color: #94B527 !important;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 6px;
  }

  .add_pipeline_btn:focus {
    color: white;
  }
</style>
