<template>
  <div class="pl-3 pr-3">
    <card type="default" header-classes="bg-transparent" v-loading="loader">
      <h4 class="edit-patient-card-headings text-uppercase text-gray-400 font-weight-400 mb-4">Surgery</h4>
      <div class="patient-detail-form-container" id="surgery-form-container">
        <el-form :model="form" :rules="surgeryFormRules" ref="surgeryForm" label-position="top" label-width="120px">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <el-form-item label="Initial Stage" prop="inital_stage">
                <el-select
                  class="w-100 select-primary pagination-select"
                  v-model="form.inital_stage"
                  @change="surgeryInfoChanged('inital_stage')"
                  placeholder="Initial Stage"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in dropdowns.stages"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-xs-12 col-md-6">
              <el-form-item label="Current Stage" prop="current_stage">
                <el-select
                  class="w-100 select-primary pagination-select"
                  v-model="form.current_stage"
                  @change="surgeryInfoChanged('current_stage')"
                  placeholder="Current Stage"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in dropdowns.stages"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-xs-12 col-md-6">
              <div>
                <el-form-item label="Surgery Date" >
                  <b-input-group class="mb-3">
                    <el-input style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                              v-mask="'##/##/####'"
                              id="example-input"
                              v-model="form.surgery_date"
                              type="text"
                              placeholder="MM/DD/YYYY"
                              autocomplete="off"
                              @blur="handleSurgeryDate"
                              @change="surgeryInfoChanged('surgery_date')"
                    ></el-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        right
                        locale="en-US"
                        aria-controls="example-input"
                        :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                        @context="onContext"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </el-form-item>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <el-form-item label="Type Of Surgery">
                <el-select
                  class="w-100 select-primary pagination-select"
                  v-model="form.type_of_surgery"
                  @change="surgeryInfoChanged('type_of_surgery')"
                  placeholder="Type Of Surgery"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in dropdowns.types_of_surgery"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </card>
  </div>

</template>

<script>
import patientConstants from "@/constants/patientConstants";
import pickerOptions from "@/constants/pickerOptions";
import moment from "moment";

export default {
  name: "SurgeryCard",
  props: ['patient', 'loader'],
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      form: {
        inital_stage: '',
        current_stage: '',
        surgery_date: '',
        type_of_surgery: '',
      },
      dropdowns: {
        stages: patientConstants.stages,
        types_of_surgery: patientConstants.types_of_surgery,
      },
      surgeryFormRules: {
        inital_stage: [
          {required: true, message: 'Please select initial stage', trigger: 'blur'},
        ],
        current_stage: [
          {required: true, message: 'Please select current stage', trigger: 'blur'}
        ]
      },
      pickerOptions: {
        surgeryDateOptions: pickerOptions.surgeryDateOptions,
      }
    }
  },
  watch: {
    patient(newVal) {
      this.assignValues(newVal)
    }
  },
  methods: {
    notifyError(field, stage, message) {
      this.$notify.error({
        title: 'Patient Current Stage',
        message: message
      })
      this.form[field] = stage
    },

    surgeryInfoChanged(field) {
      let vm = this
      if (field === 'surgery_date') {
        if (vm.form.surgery_date && moment(vm.form.surgery_date) < moment() && (vm.form['current_stage'] == '25' || vm.form['current_stage'] == '50')) {
          vm.form['current_stage'] = '75'
          return
        } else if (vm.form.surgery_date && moment(vm.form.surgery_date) > moment() && vm.form['current_stage'] == '75') {
          vm.form['current_stage'] = '50'
          return
        }
      } else if (field === 'current_stage') {
        if (vm.form.surgery_date && moment(vm.form.surgery_date) < moment() && (vm.form[field] == '25' || vm.form[field] == '50')) {
          vm.notifyError(field, '75', 'Your surgery date is already passed. Change your surgery date to the future if you\'d like to be pre-op.')
          return
        } else if (vm.form.surgery_date && moment(vm.form.surgery_date) > moment() && vm.form[field] == '75') {
          vm.notifyError(field, '50', 'Your surgery date is in the future. Please change your surgery date to the past if you\'d like to be post-op.')
          return
        }
      }
      this.$emit('patientEdited', vm.patient[field] != vm.form[field] ? true : false)
    },
    handleSurgeryDate(e)
    {
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date'){
        this.form.surgery_date = '';
      }
    },
    onContext(ctx) {
      if (ctx.selectedYMD != ''){
        this.form.surgery_date = ctx.selectedFormatted
        this.surgeryInfoChanged('surgery_date')
      }
    },
    assignValues(patient) {
      this.form.inital_stage = patient.inital_stage;
      this.form.current_stage = patient.current_stage;
      this.form.surgery_date = patient.surgery_date? moment(patient.surgery_date).format('MM/DD/YYYY') : '';
      this.form.type_of_surgery = patient.type_of_surgery;
    },
  },
}
</script>

<style scoped>

</style>

<style>
.masked-date-input {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}
</style>
