export default {
  stages: [
    {
      value: '',
      label: 'Select Stage'
    }, {
      value: '25',
      label: 'Considering Surgery'
    }, {
      value: '50',
      label: 'Pre-Op'
    }, {
      value: '75',
      label: 'Post-Op'
    }, {
      value: '150',
      label: 'Non-Surgical'
    }
  ],
  types_of_surgery: [
    {
      value: '',
      label: 'Type Of Surgery'
    },
    {
      value: 'Non-Surgical',
      label: 'Non-Surgical'
    },
    {
      value: 'Duodenal Switch',
      label: 'Duodenal Switch'
    },
    {
      value: 'Lap Band',
      label: 'Lap Band'
    },
    {
      value: 'Sleeve Gastrectomy',
      label: 'Sleeve Gastrectomy'
    },
    {
      value: 'Gastric Bypass',
      label: 'Gastric Bypass'
    },
    {
      value: 'Gastric Balloon',
      label: 'Gastric Balloon'
    },
    {
      value: 'Revision',
      label: 'Revision'
    },
    {
      value: 'SADI-S/SIPS',
      label: 'SADI-S/SIPS'
    },
  ],
  feet: [
    {
      value: '1',
      label: '1 Feet'
    },
    {
      value: '2',
      label: '2 Feet',
    },
    {
      value: '3',
      label: '3 Feet'
    },
    {
      value: '4',
      label: '4 Feet'
    },
    {
      value: '5',
      label: '5 Feet',
    },
    {
      value: '6',
      label: '6 Feet'
    },
    {
      value: '7',
      label: '7 Feet'
    },
  ],
  inches: [
    {
      value: '0',
      label: '0 inch'
    },
    {
      value: '1',
      label: '1 inch'
    },
    {
      value: '2',
      label: '2 inches',
    },
    {
      value: '3',
      label: '3 inches'
    },
    {
      value: '4',
      label: '4 inches'
    },
    {
      value: '5',
      label: '5 inches',
    },
    {
      value: '6',
      label: '6 inches'
    },
    {
      value: '7',
      label: '7 inches'
    },
    {
      value: '8',
      label: '8 inches',
    },
    {
      value: '9',
      label: '9 inches'
    },
    {
      value: '10',
      label: '10 inches'
    },
    {
      value: '11',
      label: '11 inches',
    },
  ],
  status: [
    {
      value: 1,
      label: 'Active'
    }, {
      value: 0,
      label: 'Inactive'
    }
  ],
  patient_stages: {
    'Considering Surgery' : ['25'],
    'Pre-Op'              : ['50'],
    'Post-Op'             : ['75', '100', '125'],
    'Non-Surgical'        : ['150']
  }

}
