<template>
  <div>
    <el-dialog
      title="Move Patient"
      :visible.sync="changeDialogVisible"
      width="500px"
      :before-close="handleClose">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" @submit.prevent="handleSubmit(onSubmit)">
          <base-input label="Pipeline" class=" ml-2 mt--3">
            <el-select
              class="select-primary pagination-select"
              v-model=pipeline_index
              placeholder="Pipelines"
            >
              <el-option
                class="select-primary"
                v-if="pipeline.title != 'Lost-Patients'"
                v-for="(pipeline , index) in pipelines"
                :key="index"
                :label="pipeline.editable_title"
                :value="index"
              >
              </el-option>
            </el-select>
          </base-input>

          <base-input :rules="{required: true}" name="stage" label="Stage" class=" ml-2">
            <el-select
              class="select-primary pagination-select"
              v-model=stage_index
              placeholder="Stages"
            >
              <el-option
                class="select-primary"
                v-for="(stage , index) in stages"
                :key="index"
                :label="stage.title"
                :value="index"
              >
              </el-option>
            </el-select>
          </base-input>
          <base-input v-if="selectedPipelineTitle == 'Lost-Patients'"
                      class="text-break"
                      name="resetDaysText"
                      label="Do you want to delete the number of days this patient has spent in each stage?
                      (type DELETE DAYS)"
                      placeholder="DELETE DAYS"
                      v-model="resetDaysText"
          />
          <div class="text-right mt-4">
            <el-button class="cancel-btn" @click="handleClose">Cancel</el-button>
            <el-button :disabled="resetDaysState"
                       class="move_btn" native-type="submit" type="primary" >Move</el-button>
          </div>
        </form>
      </validation-observer>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: ['changeDialogVisible', 'pipelines','pipeline_patient', 'selectedPipelineTitle'],
    data() {
      return {
        stages: [],
        pipeline_index: '',
        stage_index: '',
        resetDays: false,
        resetDaysText: '',
      };
    },
    created() {

    },
    watch: {
      pipeline_index: {
        handler: function (val) {
          this.stages = this.pipelines[val]?.stages || [];
          this.stage_id = null
        }
      },

      'resetDaysText': function () {
        this.resetDays = this.resetDaysText.toLowerCase() == 'delete days'
      }
    },
    computed: {
      resetDaysState() {
        return this.resetDaysText != '' && this.resetDaysText.toLowerCase() != 'delete days'
      },
    },
    methods: {
      resetForm() {
        this.pipeline_index = '';
        this.stage_index = '';
        this.resetDaysText = ''
        this.resetDays = false
      },

      handleClose(done) {
        this.$emit('closeDialog');
        this.$emit('removeFromDialog',this.pipeline_patient)
        this.resetForm();
        this.$refs.formValidator.reset();
      },
      onSubmit() {
        let stage_id = this.pipelines[this.pipeline_index].stages[this.stage_index].id || 0;
        if (stage_id == this.pipeline_patient.pipeline_stage_id) {
          this.$emit('removeFromDialog', this.pipeline_patient)
        } else {
          let stage_id = this.pipelines[this.pipeline_index].stages[this.stage_index].id;
          let pipeline_id = this.pipelines[this.pipeline_index].id;
          this.$emit('movePatient', stage_id, this.pipeline_patient, pipeline_id, this.stage_index, null, null, this.resetDays);
        }
        this.resetForm();
        this.$refs.formValidator.reset();
        this.$emit('closeDialog');
      }
    },
    mounted() {

    }
  };
</script>

<style scoped>

  .move_btn {
    top: 505px;
    left: 850px;
    width: 103px;
    height: 45px;
    background: #832C44 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #32325D26;
    border-radius: 6px;
    border: 0px;
    color: white;
    opacity: 1;
  }

  .cancel-btn {
    top: 505px;
    left: 756px;
    width: 79px;
    height: 45px;
    background: #8898AA 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #32325D26;
    border: 0px;
    border-radius: 6px;
    color: white;
    opacity: 1;
  }

  .move_btn[disabled]:hover {
    cursor: not-allowed;
  }

  .move_btn[disabled]{
    background-color: #b9ce72;
  }

</style>

